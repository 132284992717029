import * as React from 'react';
import styled from 'styled-components';
import MediumHeader from 'styleguide/components/Typography/MediumHeader/MediumHeader';
import { media } from 'styleguide/helpers/media';
import { HighlightedLinkItem } from './interfaces/HighlightedLink';
import LargeImageLink from '../LargeImageLink/LargeImageLink';

const WidgetWrapper = styled.div`
	padding: 50px 0 60px;
	margin: 0 auto;
	text-align: center;
`;

const WidgetHeaderWrapper = styled(MediumHeader)`
	z-index: 10;
	position: relative;
	margin-top: 0;
`;

const HighlightedLinkCarouselWrapper = styled.div`
	display: flex;
	flex-flow: column wrap;
	position: relative;

	${media.tablet`
    flex-flow: row nowrap;
  `};
`;

const ImageLinkWrapper = styled.div`
	position: relative;
	flex: 0 1 50%;
	width: 50%;

	&:nth-child(even) {
		justify-self: flex-end;
		align-self: flex-end;
		top: -95px;
	}

	&:nth-child(3) {
		margin-top: -175px;
	}

	${media.tablet`
    flex: 0 1 25%;
    width: auto;

    &:nth-child(even) {
      justify-self: flex-start;
      align-self: flex-start;
      top: 0;
    }

    &:nth-child(odd) {
      padding-top: 70px;
    }

    &:nth-child(3) {
      margin-top: 0;
    }
  `};
`;

export interface HighlightedLinkCarouselProps {
	id: string;
	title: string;
	links: HighlightedLinkItem[];
}

const HighlightedLinkCarousel: React.FC<HighlightedLinkCarouselProps> = props => {
	const { id, title, links } = props;

	const renderLink = (link: HighlightedLinkItem, index: number) => (
		<ImageLinkWrapper key={index}>
			<LargeImageLink imgSrc={`${link.imageUrl}/200x200-seasonal-highlight`} url={link.url}>
				{link.title}
			</LargeImageLink>
		</ImageLinkWrapper>
	);

	return (
		<WidgetWrapper id={id}>
			<WidgetHeaderWrapper>{title}</WidgetHeaderWrapper>
			<HighlightedLinkCarouselWrapper>{links.map(renderLink)}</HighlightedLinkCarouselWrapper>
		</WidgetWrapper>
	);
};

export default HighlightedLinkCarousel;
