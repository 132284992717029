import { WidgetConfiguration } from './interfaces';

export interface ChessSquaresItem {
	title: string;
	content: string;
	linkUrl?: string;
	linkText?: string;
	imageUrl: string;
}

export enum ChessSquaresLayoutType {
	default = '',
	oddlygood = 'oddlygood',
}

export interface ChessSquaresConfiguration {
	title?: string;
	items: ChessSquaresItem[];
	firstItemImagePosition?: 'left' | 'right';
	layoutName: ChessSquaresLayoutType;
}

export interface ChessSquaresWidget extends WidgetConfiguration {
	widgetName: 'ChessSquaresFrodget';
	configuration: ChessSquaresConfiguration;
}
