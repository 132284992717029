import * as React from 'react';
import styled, { ThemeContext } from 'styled-components';
import LabeledIconButtonLink from 'styleguide/components/Buttons/LabeledIconButton/LabeledIconButtonLink';
import ArrowRightIcon from 'styleguide/components/Icons/NormalIcons/ArrowRightIcon/ArrowRightIcon';
import { media } from 'styleguide/helpers/media';
import { LinkConfiguration } from './interfaces/Link';
import { darken } from 'utils/color';
import { ContentThemeContext } from '../../contexts/ContentThemeContext';
import { WIDGET_SPACING_LARGE, WIDGET_SPACING_HUGE } from './constants';
import { WidgetCommonProps } from './interfaces/interfaces';
import { isAbsoluteUrl } from 'utils/url';
import OpenIcon from 'styleguide/components/Icons/NormalIcons/OpenIcon';

const LinkOuterWrapper = styled.div`
	padding: 0 15px;
	max-width: 850px;
	text-align: center;
	margin: ${WIDGET_SPACING_LARGE}px auto;

	${media.tablet`
		padding: 0 35px;
	`};

	${media.desktop`
		padding: 0;
		margin: ${WIDGET_SPACING_HUGE}px auto;
	`};
`;

const LinkInnerWrapper = styled.div`
	margin: 20px 0 0;

	${media.desktop`
		margin: 30px 0 0;
	`};

	.link-link {
		display: inline-flex;
	}
`;

const Link: React.FC<WidgetCommonProps & LinkConfiguration> = props => {
	const { widgetId, linkText, linkUrl } = props;
	if (!linkText || !linkUrl) {
		return null;
	}
	const urlAbsolute = isAbsoluteUrl(linkUrl);
	const theme = React.useContext(ThemeContext);

	return (
		<ContentThemeContext.Consumer>
			{contentTheme => (
				<LinkOuterWrapper id={widgetId} className="link-outer-wrapper">
					<LinkInnerWrapper>
						<LabeledIconButtonLink
							className="link-link"
							icon={urlAbsolute ? <OpenIcon /> : <ArrowRightIcon />}
							href={linkUrl}
							backgroundColor={theme.colors.buttonLabeledBackground || contentTheme.color}
							backgroundHoverColor={theme.colors.buttonLabeledBackgroundHover || darken(contentTheme.color, 5)}
							fontStyle="primary"
							size={{ default: 'medium', desktop: 'large' }}
							isFilled={true}
							iconPlacement="right"
							target={urlAbsolute ? '_blank' : '_self'}>
							{linkText}
						</LabeledIconButtonLink>
					</LinkInnerWrapper>
				</LinkOuterWrapper>
			)}
		</ContentThemeContext.Consumer>
	);
};

export default Link;
