import { WidgetConfiguration } from './interfaces';

export enum HighlightedLinkWidgetType {
	carousel = 'carousel',
	pills = 'pills',
}

export interface HighlightedLinkItem {
	title: string;
	url: string;
	isIntense: boolean;
	imageUrl: string;
}

export interface HighlightedLinkConfiguration {
	type: HighlightedLinkWidgetType;
	highlightedLinks: HighlightedLinkItem[];
}

export interface HighlightedLinkWidget extends WidgetConfiguration {
	widgetName: 'HighlightedlinkFrodget';
	configuration: HighlightedLinkConfiguration;
}
