import styled from 'styled-components';
import { media } from 'styleguide/helpers/media';

const ArticleLead = styled.p`
	font-size: 18px;
	line-height: 1.2;
	width: 100%;
	margin: 14px auto;
	padding: 0 10px;

	${media.desktop`
		font-size: 28px;
		max-width: 600px;
		padding: 0;
	`};

	${media.desktop1440`
		max-width: 720px;
	`};
`;

export default ArticleLead;
