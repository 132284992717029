import * as React from 'react';
import DividerLine from 'styleguide/components/Divider/Divider';
import { DividerSize, DividerDirection } from 'styleguide/components/Divider/Divider';
import styled from 'styled-components';
import { DividerConfiguration } from './interfaces/Divider';
import { WidgetCommonProps } from './interfaces/interfaces';
import { media } from 'styleguide/helpers';

const DividerWrapper = styled.div`
	margin: ${({ theme }) => theme.grid.gutterInPx(2)} auto;

	${media.tablet`
		margin: ${({ theme }) => theme.grid.gutterInPx(4)} auto;
	`};
`;

const Divider: React.FC<WidgetCommonProps & DividerConfiguration> = props => {
	return (
		<DividerWrapper id={props.widgetId} className="divider-wrapper">
			<DividerLine size={DividerSize.full} direction={DividerDirection.horizontal} />
		</DividerWrapper>
	);
};

export default Divider;
