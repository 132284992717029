import { FormSubmitApi, FormSubmitApiResponse } from '../interfaces/Form';
import { postJson } from '../../../../utils/service';

export async function submitForm(
	siteUrlId: string,
	resourceId: string,
	payload: FormSubmitApi
): Promise<FormSubmitApiResponse> {
	return await postJson(`/api/sites/${siteUrlId}/forms/${resourceId}`, payload, true);
}
