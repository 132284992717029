import { WidgetConfiguration } from './interfaces';
import { FormContextValues } from 'react-hook-form';
import includes from 'lodash/includes';
import { EventTime } from '../../../../pagetypes/Event/interfaces';
import { StringArrayMap } from 'common/interfaces/common';
import { ArticleCardInterface } from 'styleguide/interfaces';

export interface FormValues {
	[key: string]: string;
}

export type WithFormContextValues = Pick<FormContextValues, 'register' | 'errors'>;
export type FormLayoutType = 'accordion';

export interface SubmitSuccessModalProps extends FormSubmitApiResponse {
	onClose: () => void;
	icon: string;
	postFormSubmitMessage: string;
	submitDialogSubTitle?: string;
	articles: ArticleCardInterface[];
}

export interface FormWidget extends WidgetConfiguration {
	widgetName: 'FormWidget';
	configuration: FormWidgetConfiguration;
}

export interface FormPersonContact {
	name: string;
	title: string;
	intro: string;
	image: string;
}

export interface FormWidgetLeadSection {
	buttonIcon?: string;
	buttonText?: string;
	description?: string;
	subTitle?: string;
	person?: FormPersonContact;
}

export interface FormWidgetConfiguration {
	icon: string;
	id: string;
	ingress: string;
	postFormSubmitButtonTitle: string;
	title: string;
	sections: FormWidgetSection[];
	linkedInAutoFillMap?: StringArrayMap;
	useCaptcha: boolean;
	leadSection?: FormWidgetLeadSection;
	layoutName?: FormLayoutType;
	postFormSubmitMessage: string;
	submitDialogSubTitle?: string;
	articles: ArticleCardInterface[];
}

export interface FormWidgetSection {
	translationId: string | null;
	fields: FormField[];
}

export interface FormSectionsProps {
	children: React.ReactNode;
	expanded: boolean;
	layoutName?: FormLayoutType;
	setExpanded: (result: boolean) => void;
}

export interface FormWidgetSectionProps {
	children: React.ReactNode;
	layoutName?: FormLayoutType;
	setExpanded: (result: boolean) => void;
	index: number;
}

export type FormField =
	| BooleanInputFormField
	| OpenInputFormField
	| SelectionInputFormField
	| DateSelectionInputFormField;

interface FormFieldBase {
	id: string;
	translationId: string | null;
	inputType: FormInputType;
	maxLength: number | undefined;
	isMandatory: boolean;
}

export interface BooleanInputFormField extends FormFieldBase {
	inputType: FormInputType.Checkbox;
	selectionValues: null;
}

export interface OpenInputFormField extends FormFieldBase {
	inputType:
		| FormInputType.Text
		| FormInputType.Email
		| FormInputType.TextArea
		| FormInputType.PhoneNumber
		| FormInputType.PostalCode
		| FormInputType.VATCode;
	selectionValues: null;
}

export interface SelectionInputFormField extends FormFieldBase {
	inputType: FormInputType.Dropdown;
	selectionValues: SelectionValue[];
}

interface SelectionValue {
	title: string;
	value: string;
}

export interface DateSelectionInputFormField extends FormFieldBase {
	inputType: FormInputType.DateDropdown;
	selectionValues: DateSelectionValue[];
}

export type DateSelectionValue = Pick<SelectionValue, 'value'> & EventTime;

export enum FormInputType {
	Text = 'Text',
	Email = 'Email',
	Dropdown = 'Dropdown',
	DateDropdown = 'DateDropdown',
	Checkbox = 'Checkbox',
	TextArea = 'TextArea',
	PostalCode = 'PostalCode',
	PhoneNumber = 'PhoneNumber',
	VATCode = 'VATCode',
}

export function isBooleanInputFormField(field: FormField): field is BooleanInputFormField {
	return field.inputType === FormInputType.Checkbox;
}

export function isOpenInputFormField(field: FormField): field is OpenInputFormField {
	return includes(
		[
			FormInputType.Text,
			FormInputType.Email,
			FormInputType.TextArea,
			FormInputType.PostalCode,
			FormInputType.PhoneNumber,
			FormInputType.VATCode,
		],
		field.inputType
	);
}

export function isSelectionInputFormField(field: FormField): field is SelectionInputFormField {
	return field.inputType === FormInputType.Dropdown;
}

export function isDateSelectionInputFormField(field: FormField): field is DateSelectionInputFormField {
	return field.inputType === FormInputType.DateDropdown;
}

export interface FormSubmitApi {
	id: string;
	fields: FormSubmitApiField[];
}

interface FormSubmitApiField {
	id: string;
	value: string | null;
}

export interface FormSubmitApiResponse {
	eventLink?: string;
}
