import * as React from 'react';
import styled, { css } from 'styled-components';
import { media } from 'styleguide/helpers/media';
import ButtonLink from 'styleguide/components/Buttons/Button/ButtonLink';
import ContentTag from 'styleguide/components/Tags/ContentTag/ContentTag';
import { FramedImageBannerConfiguration } from './interfaces/FramedImageBanner';
import { darken, isDarkColor } from 'utils/color';
import { ContentThemeContext } from '../../contexts/ContentThemeContext';
import { WIDGET_SPACING_MEDIUM, WIDGET_SPACING_LARGE } from './constants';
import { WidgetCommonProps } from './interfaces/interfaces';
import { LazyImage, LazyImageProps } from 'styleguide/components/LazyImage';

const FramedImageBannerWrapper = styled.article`
	.frame-card--outside {
		display: block;

		${media.tablet`
			display: none;
		`};
	}
`;

const FramedImageWrapper = styled.div`
	padding: ${props => props.theme.grid.gutterInPx()} ${props => props.theme.grid.gutterInPx()} 0;
	margin: ${WIDGET_SPACING_MEDIUM}px 0 0 ${props => props.theme.grid.gutterInPx(-1)};
	width: 100vw;
	height: 250px;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: cover;

	${media.phone`
		padding: ${props => props.theme.grid.gutterInPx(2)} ${props => props.theme.grid.gutterInPx(2)} 0;
		margin: ${WIDGET_SPACING_MEDIUM}px 0 0 ${props => props.theme.grid.gutterInPx(-2)};
	`};

	${media.tablet`
		width: 100%;
		border-radius: 3px;
		padding: 30px;
		height: auto;
		min-height: 540px;
		margin: ${WIDGET_SPACING_LARGE}px auto;
	`};

	.frame-card {
		display: none;

		${media.tablet`
			display: block;
		`};
	}
`;

type FrameProps = Partial<FramedImageBannerConfiguration>;

const Frame = styled.div<FrameProps>`
	border: 2px solid ${props => props.theme.colors.white};
	border-radius: 3px 3px 0 0;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	border-bottom: none;

	${media.tablet<FrameProps>`
		border: 2px solid ${props => props.theme.colors.white};
		border-radius: 3px;
		padding: 40px;
		min-height: 480px;

		${props => {
			if (props.layoutName === 'left') {
				return css`
					justify-content: flex-start;
				`;
			}
			if (props.layoutName === 'right') {
				return css`
					justify-content: flex-end;
				`;
			}
			return null;
		}};

	`};
`;

interface FrameCardProps {
	hasContentTag?: boolean;
	themeColor?: string;
}

const FrameCardContent = styled.article<FrameCardProps>`
	font-family: ${props => props.theme.fonts.primary};
	text-align: center;
	background: ${props => props.theme.colors.white};
	border: 2px solid ${props => props.themeColor || props.theme.colors.brandPrimary};
	border-top: none;
	border-radius: 0 0 3px 3px;
	padding: 40px 10px;
	position: relative;
	width: 100%;
	margin-bottom: ${WIDGET_SPACING_MEDIUM}px;

	${media.tablet<FrameCardProps>`
		border-radius: 3px;
		border: none;
		padding: 45px;
		max-width: 400px;
		margin-top: ${props => (props.hasContentTag ? '40px' : '0')};
		margin-bottom: 0;
	`};

	.framed-image-banner-link {
		margin-top: 20px;
	}

	.framed-image-banner-content-tag {
		position: absolute;
		transform: translate(-50%, -50%);
		left: 50%;
		top: 0;
	}
`;

const BannerTitle = styled.h3`
	font-size: 24px;
	line-height: 1;
	font-weight: 600;
	margin: 0 0 15px;
	letter-spacing: -0.33px;

	${media.desktop`
		font-size: 34px;
		letter-spacing: -0.5px;
	`};
`;

const BannerText = styled.p`
	font-size: 15px;
	line-height: 1.33;
	margin: 0;

	${media.desktop`
		font-size: 18px;
	`};
`;

const FramedImageBanner: React.FC<WidgetCommonProps & FramedImageBannerConfiguration> = ({
	widgetId,
	imageUrl,
	layoutName,
	...frameCardProps
}) => {
	const renderImageBannerWrapper = ({ src }: LazyImageProps) => (
		<FramedImageWrapper
			className="framed-image-banner-wrapper"
			style={{
				backgroundImage: `url(${src})`,
			}}>
			<Frame layoutName={layoutName}>
				<FrameCard {...frameCardProps} />
			</Frame>
		</FramedImageWrapper>
	);

	return (
		<FramedImageBannerWrapper id={widgetId}>
			<LazyImage src={`${imageUrl}/1120x480-framed-image-banner`} render={renderImageBannerWrapper} />
			<FrameCard {...frameCardProps} isOutsideFrame={true} />
		</FramedImageBannerWrapper>
	);
};

function FrameCard({
	title,
	text,
	linkText,
	linkUrl,
	contentFamily,
	isOutsideFrame,
}: Omit<FramedImageBannerConfiguration, 'imageUrl'> & { isOutsideFrame?: boolean }) {
	const classNames = ['frame-card'];
	if (isOutsideFrame) {
		classNames.push('frame-card--outside');
	}

	return (
		<ContentThemeContext.Consumer>
			{contentTheme => {
				const background = contentTheme.color;
				const backgroundHover = darken(contentTheme.color, 5);
				const foregroundStyle = isDarkColor(background) ? 'light' : 'dark'; // dark background -> light foreground and vice versa

				return (
					<FrameCardContent
						hasContentTag={contentFamily !== null || contentFamily !== undefined}
						themeColor={contentTheme.color}
						className={classNames.join(' ')}>
						{contentFamily && (
							<ContentTag color={contentTheme.color} size="large" className="framed-image-banner-content-tag">
								{contentFamily}
							</ContentTag>
						)}
						<BannerTitle>{title}</BannerTitle>
						<BannerText>{text}</BannerText>
						{linkText && linkUrl && (
							<ButtonLink
								className="framed-image-banner-link"
								href={linkUrl}
								foregroundColorStyle={foregroundStyle}
								backgroundColor={background}
								backgroundHoverColor={backgroundHover}
								borderColor={background}
								borderHoverColor={backgroundHover}
								fontStyle="secondary"
								size={{ default: 'small', desktop: 'medium' }}>
								{linkText}
							</ButtonLink>
						)}
					</FrameCardContent>
				);
			}}
		</ContentThemeContext.Consumer>
	);
}

export default FramedImageBanner;
