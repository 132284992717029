import * as React from 'react';
import { ResourceHighlightConfiguration, ResourceHighlightResource } from './interfaces/ResourceHighlight';
import injectRoutable, { InjectedRoutableProps } from '../Routing/Routable';
import { WidgetCommonProps } from './interfaces/interfaces';
import styled, { ThemeContext } from 'styled-components';
import { media } from 'styleguide/helpers/media';
import { WIDGET_SPACING_MEDIUM, WIDGET_SPACING_LARGE } from './constants';
import { LazyImageProps, LazyImage } from 'styleguide/components/LazyImage';
import { Button } from 'styleguide/components/Buttons/Button';
import OddlyWrapper, { OddlyWrapperBackground } from '../Oddlygood/OddlyWrapper';
import OddlyWrapperInner from '../Oddlygood/OddlyWrapperInner';

const RESOURCE_IMAGE_ASPECT_RATIO = 220 / 320;
const RESOURCE_PORTRAIT_IMAGE_HEIGHT = 465;

const ResourceHighlightWrapper = styled.article`
	margin: ${WIDGET_SPACING_MEDIUM}px auto;

	${media.desktop`
    margin: ${WIDGET_SPACING_LARGE}px auto;
  `};
`;

const Resources = styled.ul`
	margin: 0;
	padding: 0;
	list-style: none;
	display: flex;
	flex-direction: row;
	flex-flow: row wrap;
	justify-content: flex-start;
	align-content: flex-start;

	${media.phone560`

		margin: ${({ theme }) => theme.grid.gutterInPx(-1)};
	`};

	${media.desktop`
		margin: ${({ theme }) => theme.grid.gutterInPx(1.5 * -1)};

	`};

	${media.desktop1200`
		justify-content: center;
		align-content: center;
	`};

	&.multi-row {
		> {
			${media.desktop1200`
				&:nth-child(n + 4) {
					margin-top: ${({ theme }) => theme.grid.gutterInPx(9)};
				}
			`};
		}
	}
`;

const Resource = styled.li<{ itemsCount: number }>`
	position: relative;
	display: block;

	${media.phone560`
		margin: ${({ theme }) => theme.grid.gutterInPx()};
		flex: 1 0 auto;
		flex: 0 0 calc(50% - ${({ theme }) => theme.grid.gutterInPx(2)});
		width: calc(50% - ${({ theme }) => theme.grid.gutterInPx(2)});
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	`};

	${media.desktop1200<{ itemsCount: number }>`
		display: block;
		position: relative;
		min-height: ${RESOURCE_PORTRAIT_IMAGE_HEIGHT}px;
		margin: ${({ theme }) => theme.grid.gutterInPx(1.5)};
		flex: 1 0 auto;
		flex: 0 0 calc(${props => (props.itemsCount > 4 ? '31%' : '25%')} - ${({ theme }) => theme.grid.gutterInPx(3)});
		width: calc(${props => (props.itemsCount > 4 ? '31%' : '25%')} - ${({ theme }) => theme.grid.gutterInPx(3)});

	`};

	& + & {
		margin-top: ${({ theme }) => theme.grid.gutterInPx(3)};

		${media.phone560`
			margin-top: ${({ theme }) => theme.grid.gutterInPx()};
		`};

		${media.desktop1200`
			margin-top: ${({ theme }) => theme.grid.gutterInPx(1.5)};
		`};
	}

	${media.desktop`
		&:nth-child(n + 3){
			margin-top: ${({ theme }) => theme.grid.gutterInPx(1.5)};
		}
	`};
`;

interface ImageProps {
	src: string;
}

const Image = styled.div<ImageProps>`
	width: 100vw;
	background-image: url('${props => props.src}/580x399-resource-highlight');
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	padding-top: ${RESOURCE_IMAGE_ASPECT_RATIO * 100}%;
	margin: 0 ${({ theme }) => theme.grid.gutterInPx(-1)};

	${media.phone`
		margin: 0 ${({ theme }) => theme.grid.gutterInPx(2 * -1)};
	`};

	${media.phone560`
		width: 100%;
		margin: 0;
		flex: 0 0 auto;
	`};

	${media.desktop1200<ImageProps>`
		flex: none;
		background-image: url('${props => props.src}/278x465-resource-highlight');
		background-position: center top;
		padding-top: 465px;
		position: absolute;
		top: 0;
	`};
`;

const Card = styled.div`
	background: ${({ theme }) => theme.colors.white};
	padding: ${({ theme }) => theme.grid.gutterInPx(3)};
	margin: ${({ theme }) => theme.grid.gutterInPx(3 * -1)} 0 0;

	${media.phone560`
		margin: ${({ theme }) => theme.grid.gutterInPx(3 * -1)} ${({ theme }) => theme.grid.gutterInPx()} 0;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		flex: 1 0 auto;
	`};

	${media.desktop1200`
		position: relative;
		padding: ${({ theme }) => theme.grid.gutterInPx(4)} ${({ theme }) => theme.grid.gutterInPx(2)} ${({ theme }) =>
		theme.grid.gutterInPx(2)};
		margin: 280px ${({ theme }) => theme.grid.gutterInPx(2)} 0 0;
	`};

	& > .link-button {
		margin-top: ${({ theme }) => theme.grid.gutterInPx()};
		display: inline-flex;
		padding: 5px 12px;
		font-size: 11px;
		min-height: 40px;

		${media.phone`
			font-size: 13px;
		`};

		${media.desktop1200`
			margin-top: ${({ theme }) => theme.grid.gutterInPx(2)};
		`};
	}
`;

const Title = styled.h3`
	${({ theme }) => theme.typography.heading};
	font-size: 16px;
	line-height: 20px;
	color: ${({ theme }) => theme.colors.brandPrimary};
	margin: 0 0 ${({ theme }) => theme.grid.gutterInPx(0.5)};

	${media.desktop`
		font-size: 24px;
		line-height: 1;
		margin: 0 0 ${({ theme }) => theme.grid.gutterInPx()};
	`};
`;

const Summary = styled.p`
	font-size: 14px;
	line-height: 20px;
	margin: 0;

	${media.desktop`
		font-size: 16px;
		line-height: 22px;
	`};
`;

const ResourceLink = styled.a`
	height: 100%;
	text-decoration: none;
	color: ${({ theme }) => theme.colors.bodyText};
`;

const ResourceContent = styled.span`
	position: relative;
	display: block;
	height: 100%;

	${media.phone560`
		flex: 1 0 auto;
		flex: 0 0 calc(50% - ${({ theme }) => theme.grid.gutterInPx(2)});
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	`};

	&:hover {
		${Card} > .link-button {
			color: ${({ theme }) => theme.colors.buttonHighlightTextHover};
			background-color: ${({ theme }) => theme.colors.buttonHighlightBackgroundHover};
		}
	}
`;

// ==================
// Oddlygood layout
// ==================
const OddlygoodImage = styled.div`
	width: 100%;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: cover;
	padding-top: ${RESOURCE_IMAGE_ASPECT_RATIO * 100}%;
`;

const OddlyResourceHighlightWrapper = styled.article`
	margin: 0px auto;
	padding-top: ${WIDGET_SPACING_MEDIUM}px;
	padding-bottom: ${WIDGET_SPACING_MEDIUM}px;

	${media.tablet`
		padding: 0 ${({ theme }) => theme.grid.gutterInPx(2)};
	`};

	${media.desktop`
    margin: 0px auto;
		max-width: 1200px;
		padding: 0 ${({ theme }) => theme.grid.gutterInPx(2)};
		padding-top: ${WIDGET_SPACING_LARGE}px;
		padding-bottom: ${WIDGET_SPACING_LARGE}px;
  `};
`;

const OddlygoodResources = styled.ul`
	margin: 0 ${({ theme }) => theme.grid.gutterInPx()};
	padding: 0;
	list-style: none;
	display: flex;
	flex-wrap: wrap;

	${media.phone560`
		margin: ${({ theme }) => theme.grid.gutter * 1.5 * -1}px;
	`};

	@supports (display: grid) {
		margin: 0 ${({ theme }) => theme.grid.gutterInPx()};

		${media.phone560`
			margin: 0;
			display: grid;
			grid-gap: ${({ theme }) => theme.grid.gutterInPx(3)};
			grid-template-columns: repeat(2, 1fr);
		`};

		${media.tablet`
			grid-template-columns: repeat(3, 1fr);
		`};

		${media.desktop`
			grid-template-columns: repeat(4, 1fr);
		`};
	}
`;

const OddlygoodResource = styled.li`
	display: block;
	width: 100%;

	&:nth-child(n + 2) {
		display: none;
	}

	${media.phone560`
		flex: 1 0 auto;
		margin: ${({ theme }) => theme.grid.gutterInPx(1.5)};
		width: calc(50% - ${({ theme }) => theme.grid.gutterInPx(3)});

		&:nth-child(n + 2) {
			display: block;
		}
		&:nth-child(n + 3) {
			display: none;
		}
	`};

	${media.tablet`
		width: calc(100% / 3 - ${({ theme }) => theme.grid.gutterInPx(3)});

		&:nth-child(n + 3) {
			display: block;
		}
	`};

	${media.desktop`
		width: calc(25% - ${({ theme }) => theme.grid.gutterInPx(3)});
	`};

	& + & {
		margin-top: ${({ theme }) => theme.grid.gutterInPx(3)};

		${media.phone560`
			margin-top: 0;
		`};
	}

	@supports (display: grid) {
		${media.phone560`
			flex: none;
			width: auto;
			margin: 0;
		`};
	}
`;

const OddlygoodLink = styled.a`
	${({ theme }) => theme.typography.heading};
	font-size: 14px;
	line-height: 16px;
	text-decoration: none;
	color: ${({ theme }) => theme.colors.brandPrimary};
	display: block;
	margin: 1em 0px;
`;

// ==================
// ResourceHighlight widget
// ==================
const ResourceHighlight: React.FC<WidgetCommonProps & ResourceHighlightConfiguration & InjectedRoutableProps> = ({
	resources,
	layoutName,
}) => {
	if (resources.length < 1) {
		return null;
	}

	const theme = React.useContext(ThemeContext);

	const renderResource = (
		{ title, summaryText, imageUrl, linkText, linkUrl }: ResourceHighlightResource,
		index: number,
		itemsCount: number
	) => {
		const renderImage = ({ src }: LazyImageProps) => {
			return <Image src={src as string} title={`${linkText} -kuva`} />;
		};

		return (
			<Resource key={`resource-highlight-${index}`} itemsCount={itemsCount}>
				<ResourceLink href={linkUrl}>
					<ResourceContent>
						<LazyImage src={imageUrl} render={renderImage} />
						<Card>
							<div>
								<Title>{title}</Title>
								<Summary>{summaryText}</Summary>
							</div>
							<Button
								className="link-button"
								color={theme.colors.buttonHighlightText}
								hoverColor={theme.colors.buttonHighlightTextHover}
								backgroundColor={theme.colors.buttonHighlightBackground}
								backgroundHoverColor={theme.colors.buttonHighlightBackgroundHover}
								borderColor={theme.colors.buttonHighlightBorder}
								borderHoverColor={theme.colors.buttonHighlightBorderHover}>
								{linkText}
							</Button>
						</Card>
					</ResourceContent>
				</ResourceLink>
			</Resource>
		);
	};

	const renderOddlygoodResource = (
		{ title, summaryText, imageUrl, linkText, linkUrl }: ResourceHighlightResource,
		index: number,
		itemsCount: number
	) => {
		const renderImage = ({ src }: LazyImageProps) => {
			const styles = {
				backgroundImage: `url(${imageUrl}/355x234-static-highlight)`,
			};
			return <OddlygoodImage style={styles} title={`${linkText} -kuva`} />;
		};
		return (
			<OddlygoodResource key={`resource-highlight-${index}`}>
				<LazyImage src={`${imageUrl}/355x234-static-highlight`} render={renderImage} />
				<OddlygoodLink href={linkUrl}>{title}</OddlygoodLink>
			</OddlygoodResource>
		);
	};

	const resourceCount = resources.length;

	if (layoutName === 'oddlygood') {
		return (
			<OddlyWrapper background={OddlyWrapperBackground.Secondary}>
				<OddlyWrapperInner background={OddlyWrapperBackground.Secondary} desktopPadding={15} tabletPadding={15}>
					<OddlyResourceHighlightWrapper>
						<OddlygoodResources>
							{resources.map((r, index) => renderOddlygoodResource(r, index, resourceCount))}
						</OddlygoodResources>
					</OddlyResourceHighlightWrapper>
				</OddlyWrapperInner>
			</OddlyWrapper>
		);
	} else {
		return (
			<ResourceHighlightWrapper>
				<Resources className={resourceCount > 4 ? 'multi-row' : undefined}>
					{resources.map((r, index) => renderResource(r, index, resourceCount))}
				</Resources>
			</ResourceHighlightWrapper>
		);
	}
};

export default injectRoutable(ResourceHighlight);
