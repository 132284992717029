import * as React from 'react';
import OutlinedIconLink from 'styleguide/components/Links/OutlinedIconLink/OutlinedIconLink';
import { styleguideImport } from 'utils/import';

interface WayfinderIconLinkState {
	linkIcon: React.FC | React.ComponentClass | null;
}

interface WayfinderIconLinkProps {
	icon: string | null;
	url: string;
	text: string;
	isExternalLink?: boolean;
	key: any;
}

class WayfinderIconLink extends React.Component<WayfinderIconLinkProps, WayfinderIconLinkState> {
	public state: WayfinderIconLinkState = {
		linkIcon: null,
	};

	public async componentDidMount() {
		const icon = await styleguideImport(this.props.icon);
		if (icon) {
			this.setState({ linkIcon: icon });
		}
	}

	public render() {
		const { text, url, icon, isExternalLink } = this.props;
		const { linkIcon } = this.state;

		if (!icon) {
			return null;
		}

		let Icon: any = 'span';
		if (linkIcon !== null) {
			Icon = linkIcon;
		}

		return (
			<OutlinedIconLink
				icon={<Icon />}
				href={url}
				target={isExternalLink ? '_blank' : undefined}
				rel={isExternalLink ? 'noopener noreferrer' : undefined}>
				{text}
			</OutlinedIconLink>
		);
	}
}

export default WayfinderIconLink;
