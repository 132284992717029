import styled from 'styled-components';
import { media } from 'styleguide/helpers/media';

const HighlightedLinkSection = styled.section`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	width: 80%;
	margin: 0 auto 0;
	box-sizing: border-box;

	> * {
		margin-left: 4px;
		margin-right: 4px;
		margin-bottom: 10px;
	}

	${media.tablet`
		width: 100%;
  `};

	${media.desktop`
		width: 100%;
		padding: 0 120px;
  `};
`;

export default HighlightedLinkSection;
