import * as React from 'react';
import styled from 'styled-components';
import { media } from 'styleguide/helpers/media';
import { EmbeddedVideoConfiguration, VideoSource } from './interfaces/EmbeddedVideo';
import { WIDGET_SPACING_MEDIUM, WIDGET_SPACING_LARGE } from './constants';
import { WidgetCommonProps } from './interfaces/interfaces';

const EmbeddedVideoWrapper = styled.div`
	width: 100%;
	max-width: 1140px;
	margin: ${WIDGET_SPACING_MEDIUM}px auto;
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;

	${media.desktop`
		margin: ${WIDGET_SPACING_LARGE}px auto;
	`};

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
`;

const EmbeddedVideo: React.FC<WidgetCommonProps & EmbeddedVideoConfiguration> = ({
	widgetId,
	videoId,
	channelId,
	sourceType,
	videoHash,
}) => {
	let videoSrc = `https://www.youtube-nocookie.com/embed/${videoId}`;
	if (sourceType === VideoSource.DreamBroker && channelId) {
		videoSrc = `https://dreambroker.com/channel/${channelId}/iframe/${videoId}/`;
	} else if (sourceType === VideoSource.Vimeo) {
		videoSrc = `https://player.vimeo.com/video/${videoId}${videoHash ? `?h=${videoHash}` : ''}`;
	}
	return (
		<EmbeddedVideoWrapper id={widgetId} className="embedded-video-wrapper">
			<iframe
				width="100%"
				height="auto"
				src={`${videoSrc}`}
				frameBorder="0"
				data-allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
				allowFullScreen
			/>
		</EmbeddedVideoWrapper>
	);
};

export default EmbeddedVideo;
