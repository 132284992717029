import * as React from 'react';
import styled from 'styled-components';
import LabeledIconButtonLink from 'styleguide/components/Buttons/LabeledIconButton/LabeledIconButtonLink';
import ArrowRightIcon from 'styleguide/components/Icons/NormalIcons/ArrowRightIcon/ArrowRightIcon';
import { media } from 'styleguide/helpers/media';
import { QuotationConfiguration } from './interfaces/Quotation';
import { darken } from 'utils/color';
import { ContentThemeContext } from '../../contexts/ContentThemeContext';
import { WIDGET_SPACING_LARGE, WIDGET_SPACING_HUGE } from './constants';
import { WidgetCommonProps } from './interfaces/interfaces';

const QuotationWrapper = styled.div`
	padding: 0 15px;
	max-width: 850px;
	text-align: center;
	margin: ${WIDGET_SPACING_LARGE}px auto;

	${media.tablet`
		padding: 0 35px;
	`};

	${media.desktop`
		margin: ${WIDGET_SPACING_HUGE}px auto;
		padding: 0;
	`};
`;

const QuotationText = styled.q<{ color: string; excludeQuotes: boolean }>`
	color: ${props => props.color};
	font-family: ${props => props.theme.fonts.secondary};
	font-size: 24px;
	font-weight: 600;
	line-height: 1.25;
	text-align: center;
	margin: 0 0 20px;
	quotes: ${props => (props.excludeQuotes ? 'none' : `'“' '”' '‘' '’'`)};

	${media.desktop`
		font-size: 40px;
	`};
`;

const QuotationAttribution = styled.div`
	font-family: ${props => props.theme.fonts.secondary};
	font-size: 18px;
	line-height: 1.25;
	text-align: center;
	margin: 20px 0 0;

	${media.desktop`
		margin: 30px 0 0;
		font-size: 24px;
	`};
`;

const QuotationLinkWrapper = styled.div`
	margin: 20px 0 0;

	${media.desktop`
		margin: 30px 0 0;
	`};

	.quotation-link {
		display: inline-flex;
	}
`;

const Quotation: React.FC<WidgetCommonProps & QuotationConfiguration> = props => {
	const { widgetId, text, attribution, linkText, linkUrl, excludeQuotes } = props;
	return (
		<ContentThemeContext.Consumer>
			{contentTheme => (
				<QuotationWrapper id={widgetId} className="quotation-wrapper">
					<QuotationText color={contentTheme.color} excludeQuotes={excludeQuotes}>
						{text}
					</QuotationText>
					{attribution && <QuotationAttribution>{attribution}</QuotationAttribution>}
					{linkText && linkUrl && (
						<QuotationLinkWrapper>
							<LabeledIconButtonLink
								className="quotation-link"
								icon={<ArrowRightIcon />}
								href={linkUrl}
								backgroundColor={contentTheme.color}
								backgroundHoverColor={darken(contentTheme.color, 5)}
								fontStyle="secondary"
								size={{ default: 'medium', desktop: 'extra-large' }}
								isFilled={true}
								iconPlacement="right">
								{linkText}
							</LabeledIconButtonLink>
						</QuotationLinkWrapper>
					)}
				</QuotationWrapper>
			)}
		</ContentThemeContext.Consumer>
	);
};

export default Quotation;
