import * as React from 'react';
import styled from 'styled-components';
import { media } from 'styleguide/helpers/media';
import ContentTag from 'styleguide/components/Tags/ContentTag/ContentTag';
import { EmbeddedArticleConfiguration } from './interfaces/EmbeddedArticle';
import { ContentThemeContext, ContentTheme } from 'common/contexts/ContentThemeContext';
import WidgetZone from './WidgetZone';
import { WIDGET_SPACING_LARGE, WIDGET_SPACING_HUGE } from './constants';
import ArticleAuthor from 'pagetypes/CmsContent/layouts/Article/components/ArticleAuthor';
import ArticleLead from 'pagetypes/CmsContent/layouts/Article/components/ArticleLead';
import { WidgetCommonProps } from './interfaces/interfaces';

const EmbeddedArticleWrapper = styled.article<ContentTheme>`
	margin: ${WIDGET_SPACING_LARGE}px auto;
	width: 100%;
	border: 2px solid ${props => props.color};
	border-radius: 3px;
	position: relative;
	padding: 20px 10px;

	${media.tablet`
		padding: 20px;
		margin: ${WIDGET_SPACING_LARGE}px -20px;
		width: calc(100% + 40px);
	`};

	${media.desktop`
		width: 100%;
		padding: 45px 3%;
		margin: ${WIDGET_SPACING_HUGE}px auto;
	`};

	${media.desktop1440`
		padding: 45px 100px;
	`};

	.content-tag {
		position: absolute;
		top: -24px;

		${media.desktop`
			top: -30px;
		`};
	}

	.article-author {
		text-align: center;
		width: 100%;
		margin: 0 auto;
		padding: 0 10px;

		${media.tablet`
			width: 50%;
			padding: 0;
		`};

		${media.desktop`
			position: absolute;
			width: 160px;
		`};
	}

	.widget-zone,
	.article-lead {
		${media.desktop`
			transform: translateX(30px);
		`};
		${media.desktop1440`
			transform: translateX(75px);
		`};
	}

	.article-lead {
		${media.tablet`
			text-align: center;
		`};

		${media.desktop`
			text-align: left;
		`};
	}
`;

const ArticleHeader = styled.header`
	text-align: center;

	${media.tablet`
		width: 80%;
		margin: 0 auto;
	`};

	${media.desktop`
		width: 50%;
		text-align: left;
		margin: 0;
	`};

	h3 {
		font-size: 24px;
		font-weight: 600;
		line-height: 26px;
		margin: 0 0 30px;

		${media.desktop`
			margin: 0 0 50px;
  		font-size: 36px;
      line-height: 1;
    `};
	}
`;

const EmbeddedArticle: React.FC<WidgetCommonProps & EmbeddedArticleConfiguration> = props => {
	const { widgetId, title, leadText, contentFamily, author, widgetConfigurations } = props;
	return (
		<ContentThemeContext.Consumer>
			{contentTheme => (
				<EmbeddedArticleWrapper {...contentTheme} id={widgetId} className="embedded-article-wrapper">
					{contentFamily && (
						<ContentTag color={contentTheme.color} className="content-tag">
							{contentFamily}
						</ContentTag>
					)}
					<ArticleHeader>
						<h3>{title}</h3>
					</ArticleHeader>
					{author && <ArticleAuthor {...author} />}
					<ArticleLead className="article-lead">{leadText}</ArticleLead>
					<WidgetZone name="" isFullWidth={true} customWidgets={widgetConfigurations} />
				</EmbeddedArticleWrapper>
			)}
		</ContentThemeContext.Consumer>
	);
};

export default EmbeddedArticle;
