import * as React from 'react';
import { NormalIconWrapperProps } from '../../Icons/NormalIcons/NormalIconWrapper';
import styled from 'styled-components';

const StyledOutlinedIconLink = styled.a`
	border: 2px solid ${props => props.theme.colors.borderHighlight};
	border-radius: 4px;
	background: ${props => props.theme.colors.white};
	padding: 10px 15px 15px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	color: ${props => props.theme.colors.brandPrimary};
	text-decoration: none;
`;

const LinkText = styled.span`
	font-family: ${props => props.theme.fonts.primaryButtons};
	font-size: 13px;
	font-weight: 800;
	display: block;
	text-align: center;
	width: 100%;
`;

interface OutlinedIconLinkProps {
	icon?: React.ReactElement<NormalIconWrapperProps & React.SVGAttributes<SVGElement>>;
}

const OutlinedIconLink: React.FC<OutlinedIconLinkProps & React.AnchorHTMLAttributes<HTMLAnchorElement>> = ({
	href,
	icon,
	children,
	target,
	rel,
}) => {
	const renderContent = () => (
		<>
			{icon}
			<LinkText>{children}</LinkText>
		</>
	);

	if (!href) {
		return <StyledOutlinedIconLink as="span">{renderContent()}</StyledOutlinedIconLink>;
	}

	return (
		<StyledOutlinedIconLink href={href} target={target} rel={rel}>
			{renderContent()}
		</StyledOutlinedIconLink>
	);
};

export default OutlinedIconLink;
