import { WidgetConfiguration } from './interfaces';

export interface WayfinderLink {
	url: string;
	text: string;
	icon: string;
	imageUrl?: string;
	isExternalLink?: boolean;
}

export enum WayfinderLayoutType {
	withIcons = 'withIcons',
	textOnly = 'textOnly',
	textOnlyNarrow = 'textOnlyNarrow',
	textOnlyWide = 'textOnlyWide',
	withImages = 'withImages',
	textOnlyTabs = 'textOnlyTabs',
	oddlygood = 'oddlygood',
}
export interface WayfinderConfiguration {
	links: WayfinderLink[];
	layoutName?: WayfinderLayoutType;
	title?: string;
}

export interface WayfinderWidget extends WidgetConfiguration {
	widgetName: 'WayfinderFrodget';
	configuration: WayfinderConfiguration;
}
