import * as React from 'react';
import styled from 'styled-components';
import Field, {
	commonInputStyles,
	getBorder,
	getFontWeight,
	getCssPadding,
	getInvalidStyles,
	StyledFieldProps,
	FieldProps,
} from '../Field/Field';

const StyledTextArea = styled.textarea<StyledFieldProps>`
	${commonInputStyles};

	appearance: none;
	resize: vertical;
	border: 1px solid ${props => getBorder(props)};
	font-weight: ${({ inputFontWeight }) => getFontWeight(inputFontWeight)};

	${props => getCssPadding(props)};
	${props => getInvalidStyles(props)};
`;

export interface TextAreaProps extends FieldProps {
	inputClassName?: string;
}

type Props = TextAreaProps & React.TextareaHTMLAttributes<HTMLTextAreaElement>;

const TextArea = React.forwardRef<HTMLTextAreaElement, Props>(
	(
		{
			validationError,
			helpText,
			id,
			name,
			icon,
			iconPosition,
			iconOffset,
			iconWidth,
			inputSize,
			inputClassName,
			className,
			label,
			inlineLabel,
			...props
		},
		ref: React.RefObject<HTMLTextAreaElement>
	) => {
		const textAreaId = id || name;

		return (
			<Field
				validationError={validationError}
				helpText={helpText}
				id={textAreaId}
				name={name}
				icon={icon}
				iconPosition={iconPosition}
				iconOffset={iconOffset}
				iconWidth={iconWidth}
				inputSize={inputSize}
				className={className}
				label={label}
				inlineLabel={inlineLabel}>
				<StyledTextArea
					name={name}
					id={textAreaId}
					inputSize={inputSize}
					iconWidth={iconWidth}
					iconPosition={iconPosition}
					iconOffset={iconOffset}
					className={inputClassName}
					isValid={validationError ? validationError.length === 0 : true}
					ref={ref}
					{...props}
				/>
			</Field>
		);
	}
);

export default TextArea;
