import * as React from 'react';
import Lightbox, { ReactImages } from 'react-images';

export interface LightboxProps {
	medias: ReactImages.LightboxImageProps[];
	isOpen: boolean;
	initialItem?: number;
	onClose: () => void;
}

export interface LightboxState {
	currentItem: number;
}

class LightboxImage extends React.Component<LightboxProps, LightboxState, {}> {
	public static defaultProps = {
		initialItem: 0,
	};

	constructor(props: LightboxProps) {
		super(props);
		this.state = {
			currentItem: props.initialItem || 0,
		};
	}

	public componentWillReceiveProps(nextProps: LightboxProps) {
		if (!!nextProps.initialItem && nextProps.initialItem !== this.state.currentItem) {
			this.setState({ currentItem: nextProps.initialItem });
		}
	}

	public render() {
		const { medias } = this.props;

		return medias && medias.length ? (
			<Lightbox
				currentItem={this.state.currentItem}
				items={medias}
				isOpen={this.props.isOpen}
				backdropClosesModal={true}
				imageCountSeparator=" / "
				preventScroll={true}
				onClose={this.closeLightbox}
				onClickNext={this.gotoNext}
				onClickPrev={this.gotoPrevious}
				onClickImage={this.handleClickMedia}
				onClickThumbnail={this.gotoMedia}
			/>
		) : (
			''
		);
	}

	private gotoPrevious = () => {
		this.setState({
			currentItem: this.state.currentItem - 1,
		});
	};

	private gotoNext = () => {
		this.setState({
			currentItem: this.state.currentItem + 1,
		});
	};

	private closeLightbox = () => {
		this.setState({
			currentItem: 0,
		});
		this.props.onClose();
	};

	private handleClickMedia = () => {
		if (this.state.currentItem === this.props.medias.length - 1) {
			return;
		}

		this.gotoNext();
	};

	private gotoMedia = (index: number) => {
		this.setState({
			currentItem: index,
		});
	};
}

export default LightboxImage;
