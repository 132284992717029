import * as React from 'react';
import { WayfinderConfiguration, WayfinderLayoutType } from './interfaces/Wayfinder';
import styled from 'styled-components';
import { media, mediaBreakpoints } from 'styleguide/helpers/media';
import OutlinedButtonLink from 'styleguide/components/Buttons/OutlinedButton/OutlinedButtonLink';
import OutlinedIconLinkList from 'styleguide/components/Links/OutlinedIconLinkList/OutlinedIconLinkList';
import MediumHeader from 'styleguide/components/Typography/MediumHeader/MediumHeader';
import { WIDGET_SPACING_MEDIUM, WIDGET_SPACING_LARGE } from './constants';
import { WidgetCommonProps } from './interfaces/interfaces';
import WayfinderIconLink from './components/WayfinderIconLink';
import Tabs from 'styleguide/components/Tabs/Tabs';
import { TabProps } from 'styleguide/components/Tabs/Tab';
import { withWindow } from 'styleguide/helpers';
import { useEffect, useState } from 'react';

const TitleWrapper = styled.div`
	${({ theme }) => theme.typography.heading};
	font-size: 16px;
	line-height: 1.25;
	color: ${({ theme }) => theme.colors.brandPrimary};
	margin-bottom: ${({ theme }) => theme.grid.gutterInPx(2)};
	text-transform: uppercase;

	${media.tablet`
    display: inline-block;
    font-size: 20px;
    line-height: 1.2;
    margin-right: ${({ theme }) => theme.grid.gutterInPx(2)};
    vertical-align: middle;
  `};
`;

const Button = styled(OutlinedButtonLink)`
	display: block;
	padding: 8px 50px;
	text-transform: uppercase;
	margin-bottom: ${({ theme }) => theme.grid.gutterInPx(2)};
	max-width: 275px;
	font-size: 11px;
	line-height: 22px;
	margin-left: auto;
	margin-right: auto;
	height: auto;

	${media.tablet`
    display: inline-block;
    max-width: 100%;
    font-size: 13px;
    vertical-align: middle;
    & + & {
      margin-left: ${({ theme }) => theme.grid.gutterInPx(2)};
    }
  `};
`;

const WayfinderWrapper = styled.article`
	margin: ${WIDGET_SPACING_MEDIUM}px auto;
	text-align: center;

	&.narrow {
		${media.desktop`
			display: flex;
			flex-flow: row wrap;
			justify-content: center;
			align-content: flex-start;
			max-width: ${mediaBreakpoints.tablet}px;

			> ${TitleWrapper} {
				flex: 1 0 100%;
				text-align: center;
				margin: 0 0 ${({ theme }) => theme.grid.gutterInPx(2)};
			}

			> ${Button} {
				flex: 0 0 auto;
				margin: 0 ${({ theme }) => theme.grid.gutterInPx(1)} ${({ theme }) => theme.grid.gutterInPx(2)};
			}
		`}
	}

	&.wide {
		${media.tablet`
			display: flex;
			flex-flow: row wrap;
			justify-content: center;
			align-content: flex-start;

			> ${TitleWrapper} {
				flex: 1 0 100%;
				text-align: center;
				margin: 0 0 ${({ theme }) => theme.grid.gutterInPx(2)};
			}

			> ${Button} {
				flex: 1 0 auto;
				margin: 0 ${({ theme }) => theme.grid.gutterInPx(1)} ${({ theme }) => theme.grid.gutterInPx(2)};
			}
		`}
	}

	${media.tablet`
    text-align: left;
  `};

	${media.desktop`
    margin: ${WIDGET_SPACING_LARGE}px auto;
  `};
`;

const WayfinderIconsWrapper = styled.section`
	width: 100%;
	margin: 40px 0;
	position: relative;

	${media.tablet`
  	margin: 45px 0;
    padding: 0 20px;
  `};

	${media.desktop1440`
    padding: 0 70px;
  `};

	.medium-header {
		margin: 0 0 16px;
		text-align: center;

		${media.tablet`
			margin: 0 0 10px;
		`};
	}
`;

const LinkWithImage = styled.a`
	border: 2px solid ${props => props.theme.colors.borderHighlight};
	border-radius: 4px;
	color: ${({ theme }) => theme.colors.brandPrimary};
	padding: 10px 15px 15px;
	text-decoration: none;
	display: flex;
	flex-direction: column;
	align-content: center;
	justify-content: center;
`;

const Image = styled.img`
	width: 100px;
	border-radius: 2px;
	display: flex;
	align-self: center;
`;

const LinkText = styled.span`
	display: flex;
	justify-content: center;
	font-family: ${props => props.theme.fonts.primary};
	font-size: 13px;
	font-weight: 800;
	width: 100%;
`;

function WayfinderTextOnly({ links, title }: WayfinderConfiguration) {
	return (
		<>
			<TitleWrapper>{title}</TitleWrapper>
			{links.map((link, index) => (
				<Button
					key={index}
					href={link.url}
					rel={link.isExternalLink ? 'noopener noreferrer' : undefined}
					target={link.isExternalLink ? '_blank' : undefined}>
					{link.text}
				</Button>
			))}
		</>
	);
}

const OddlygoodCenteredLinks = styled.div`
	text-align: center;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-evenly;
`;

const OddlygoodLinkButton = styled(OutlinedButtonLink)`
	display: inline;
	font-size: 17px;
	text-decoration: underline;
	margin: 8px 0px;
	transition: 0.6s font-size;

	&:hover {
		border: 1px solid transparent;
		font-size: 21px;
	}
`;

/* Oddlygood */
function WayfinderTextOnlyAsLinks({ links, title }: WayfinderConfiguration) {
	return (
		<OddlygoodCenteredLinks>
			{title && <TitleWrapper>{title}</TitleWrapper>}
			{links.map((link, index) => (
				<OddlygoodLinkButton
					key={index}
					href={link.url}
					rel={link.isExternalLink ? 'noopener noreferrer' : undefined}
					target={link.isExternalLink ? '_blank' : undefined}>
					{link.text}
				</OddlygoodLinkButton>
			))}
		</OddlygoodCenteredLinks>
	);
}

function WayfinderWithIcons({ links, title }: WayfinderConfiguration) {
	return (
		<WayfinderIconsWrapper>
			<header>
				<MediumHeader className="medium-header">{title}</MediumHeader>
			</header>
			<OutlinedIconLinkList>
				{links.map((link, index) => (
					<WayfinderIconLink key={index} {...link} />
				))}
			</OutlinedIconLinkList>
		</WayfinderIconsWrapper>
	);
}

function WayfinderWithImages({ links, title }: WayfinderConfiguration) {
	return (
		<WayfinderIconsWrapper>
			<header>
				<MediumHeader className="medium-header">{title}</MediumHeader>
			</header>
			<OutlinedIconLinkList>
				{links.map((link, index) => (
					<LinkWithImage
						key={`${link.url}-${index}`}
						href={link.url}
						title={link.text}
						target={link.isExternalLink ? '_blank' : undefined}
						rel={link.isExternalLink ? 'noopener noreferrer' : undefined}>
						<Image src={`${link.imageUrl}/180x180jpg`} alt={link.text} />
						<LinkText>{link.text}</LinkText>
					</LinkWithImage>
				))}
			</OutlinedIconLinkList>
		</WayfinderIconsWrapper>
	);
}

function WayfinderTabsText({ links, title }: WayfinderConfiguration) {
	const [tabs, setTabsState] = useState<TabProps[]>(
		links.map((l, i) => ({ url: l.url, label: l.text, isActive: i === 0 }))
	);

	useEffect(() => withWindow(w => setActiveTab(w)), []);

	const handleTabClick = (url: string) => {
		withWindow(w => {
			if (url.startsWith('#') || (url.startsWith(w.location.pathname) && url.indexOf('#') > 0)) {
				const element = w.document.getElementById(url.substr(url.indexOf('#') + 1));
				if (element) {
					// Header and subnavigation offset
					const top = w.scrollY + element.getBoundingClientRect().top - 170;
					w.scrollTo({ top, behavior: 'smooth' });
				}
			} else {
				window.location.href = url;
			}
			setActiveTab(w, url);
		});
	};
	return (
		<>
			{!!title && <TitleWrapper>{title}</TitleWrapper>}
			<Tabs tabs={tabs} onTabClick={handleTabClick} useExternalProps={true} wrapListItems={true} />
		</>
	);

	function setActiveTab(w: Window, url?: string) {
		const activeTabIndex = tabs.findIndex(t => (url ? url === t.url : w.location.href.endsWith(t.url)));
		if (activeTabIndex >= 0) {
			setTabsState(tabs.map((t, i) => ({ ...t, isActive: i === activeTabIndex })));
		}
	}
}

function WidgetComponent(props: WayfinderConfiguration) {
	const layout = props.layoutName || WayfinderLayoutType.textOnly;
	switch (layout) {
		case WayfinderLayoutType.withIcons: {
			return <WayfinderWithIcons {...props} />;
		}
		case WayfinderLayoutType.withImages: {
			return <WayfinderWithImages {...props} />;
		}
		case WayfinderLayoutType.textOnlyTabs: {
			return <WayfinderTabsText {...props} />;
		}
		case WayfinderLayoutType.oddlygood: {
			return <WayfinderTextOnlyAsLinks {...props} />;
		}
		default: {
			return <WayfinderTextOnly {...props} />;
		}
	}
}

function GetClassName(layoutName: WayfinderLayoutType) {
	let className = '';
	switch (layoutName) {
		case WayfinderLayoutType.textOnlyNarrow: {
			className = 'narrow';
		}
		case WayfinderLayoutType.textOnlyWide: {
			className = 'wide';
			break;
		}
		default: {
			break;
		}
	}
	return className;
}

const Wayfinder: React.FC<WidgetCommonProps & WayfinderConfiguration> = props => {
	const { widgetId, links, layoutName } = props;

	if (links.length < 1) {
		return null;
	}

	return (
		<WayfinderWrapper id={widgetId} className={layoutName && GetClassName(layoutName)}>
			<WidgetComponent {...props} />
		</WayfinderWrapper>
	);
};

export default Wayfinder;
