import * as React from 'react';
import { StyledIconButton, StyledIconButtonProps } from './common';
import { NormalIconWrapperProps } from '../../Icons/NormalIcons/NormalIconWrapper';
import styled from 'styled-components';

const Link = styled(StyledIconButton('a'))`
	display: inline-block;
`;

export interface IconLinkProps extends StyledIconButtonProps {
	/** The icon element to show in the button */
	icon: React.ReactElement<NormalIconWrapperProps & React.SVGAttributes<SVGElement>>;
	url?: string;
}

const IconLink: React.FC<IconLinkProps & React.AnchorHTMLAttributes<HTMLAnchorElement>> = ({ icon, url, ...rest }) => {
	return (
		<Link href={url} {...rest}>
			{icon}
		</Link>
	);
};

export default IconLink;
