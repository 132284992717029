import noop from 'lodash/noop';
import * as React from 'react';
import { useEffect, useRef } from 'react';
import { recaptchaApi } from './services';

interface ReCaptchaProps {
	siteKey: string;
	resultCallback: (result: boolean) => void;
	expiredCallback?: (result: boolean) => void;
}

const ReCaptcha: React.FC<ReCaptchaProps> = ({ siteKey, resultCallback, expiredCallback }) => {
	const recaptchaElement = useRef(null);
	const scriptId = 'recaptcha-script';

	useEffect(() => {
		createScript();
	}, []);

	return <div ref={recaptchaElement} />;

	function renderReCaptcha() {
		(window as any).grecaptcha.render(recaptchaElement.current, {
			sitekey: siteKey,
			callback: handleResponse,
			'expired-callback': () => {
				handleResponse(false);
			},
			'error-callback': () => {
				handleResponse(new Error('Recaptcha connection error!'));
			},
		});
	}

	async function handleResponse(response: string | boolean | Error) {
		if (typeof response === 'string') {
			await verifyToken(response);
		} else if (typeof response === 'boolean' && !response) {
			(expiredCallback || noop)(response);
		} else if (response instanceof Error) {
			throw response;
		}
	}

	async function verifyToken(token: string) {
		const result = await recaptchaApi.verifyRecaptcha(token);
		if (!result) {
			(window as any).grecaptcha.reset();
		}
		resultCallback(result);
	}

	function createScript() {
		const scriptElement = document.getElementById(scriptId);

		if (scriptElement) {
			setTimeout(renderReCaptcha, 1000);
			// We don't want to create multiple instances of the same script
			return;
		}

		const recaptcha = document.createElement('script');
		recaptcha.id = scriptId;
		recaptcha.onload = scriptLoaded;
		recaptcha.src = 'https://www.google.com/recaptcha/api.js?render=explicit';
		document.head.appendChild(recaptcha);
	}

	function scriptLoaded() {
		(window as any).grecaptcha.ready(renderReCaptcha);
	}
};

export default ReCaptcha;
