import { useEffect } from 'react';

export const useScript = (props: React.HTMLProps<HTMLElement>) =>
	useEffect(() => {
		const script = document.createElement('script');

		let targetElement: any;

		Object.keys(props).map(p => {
			if (p === 'ref') {
				const refTarget = props[p] as React.RefObject<any>;
				if (refTarget) {
					targetElement = document.getElementById(refTarget.current?.id);
				}
			} else {
				script.setAttribute(p, props[p].toString());
			}
		});

		if (targetElement) {
			targetElement.appendChild(script);
		} else {
			document.body.appendChild(script);
		}
		return () => {
			if (targetElement) {
				targetElement.removeChild(script);
			} else {
				document.body.removeChild(script);
			}
		};
	}, []);
