import * as React from 'react';
import styled from 'styled-components';
import ArrowRightIcon from 'styleguide/components/Icons/NormalIcons/ArrowRightIcon/ArrowRightIcon';
import { media } from 'styleguide/helpers/media';
import { CrossLinkBannersConfiguration } from './interfaces/CrossLinkBanners';
import { ContentWrapper } from '../General';
import WeeklyMenuIcon from '../WeeklyMenu/components/WeeklyMenuIcon';
import { WidgetCommonProps } from './interfaces/interfaces';

const CrossLinkBannersWrapper = styled.article`
	background: ${props => props.theme.colors.contentSectionHiglighted};
	width: 100%;
`;

const CrossLinkBannersContainer = styled.div`
	width: 100%;
	padding: 10px;
	display: flex;
	flex-direction: column;

	${media.tablet`
  	padding: 10px 40px;
  `};

	${media.desktop`
    flex-direction: row;
    padding: 40px;
  `};
`;

interface CrossLinkBannerItemProps {
	totalBanners: number;
}

const CrossLinkBannerItem = styled.a<CrossLinkBannerItemProps>`
	background: ${props => props.theme.colors.white};
	border-radius: 3px;
	padding: 10px 50px 10px 15px;
	display: flex;
	align-items: center;
	color: ${props => props.theme.colors.brandPrimary};
	text-decoration: none;
	position: relative;
	margin: 10px 0;
	flex: 1 1 auto;

	${media.desktop<CrossLinkBannerItemProps>`
  	margin: 0 10px;
    padding: 15px 28px;
		flex: 1 1 ${props => (props.totalBanners < 2 ? '100%' : '50%')};
  `};

	& > svg {
		width: 93px;
		height: 95px;
		margin-right: 15px;
		display: none;

		${media.tablet`
      display: block;
      margin-right: 28px;
			flex: 0 0 93px;
    `};
	}
`;

const CrossLinkBannerTitle = styled.h3`
	${props => props.theme.typography.heading};
	font-size: 18px;
	margin: 0;
	text-transform: uppercase;

	${media.tablet`
  	font-size: 24px;
  `};
`;

const CrossLinkBannerSubTitle = styled.p`
	font-size: 14px;
	font-weight: 600;
	line-height: 21px;
	margin: 0;

	${media.tablet`
  	font-size: 18px;
  `};
`;

const CrossLinkBannerLinkIndicator = styled.div`
	position: absolute;
	border: 1px solid ${props => props.theme.colors.brandPrimary};
	border-radius: 50%;
	width: 32px;
	height: 32px;
	text-align: center;
	top: 10px;
	right: 10px;
	display: flex;
	justify-content: center;
	align-items: center;

	& > svg {
		display: block;
		width: 18px;
		height: 18px;
	}
`;

const CrossLinkBanners: React.FC<WidgetCommonProps & CrossLinkBannersConfiguration> = ({ widgetId, banners }) => {
	const visibleBanners = banners.slice(0, 2);
	if (visibleBanners.length === 0) {
		return null;
	}
	return (
		<CrossLinkBannersWrapper id={widgetId} className="cross-link-banners-wrapper">
			<ContentWrapper>
				<CrossLinkBannersContainer>
					{visibleBanners.map(({ title, subTitle, url }, index) => (
						<CrossLinkBannerItem key={`${url}-${index}`} href={url} totalBanners={visibleBanners.length}>
							<WeeklyMenuIcon />
							<div>
								<CrossLinkBannerSubTitle>{subTitle}</CrossLinkBannerSubTitle>
								<CrossLinkBannerTitle>{title}</CrossLinkBannerTitle>
								<CrossLinkBannerLinkIndicator>
									<ArrowRightIcon />
								</CrossLinkBannerLinkIndicator>
							</div>
						</CrossLinkBannerItem>
					))}
				</CrossLinkBannersContainer>
			</ContentWrapper>
		</CrossLinkBannersWrapper>
	);
};

export default CrossLinkBanners;
