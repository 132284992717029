import * as React from 'react';
import loadable from '@loadable/component';
import { ChessSquaresConfiguration, ChessSquaresItem, ChessSquaresLayoutType } from './interfaces/ChessSquares';
import { ContentThemeContext, ContentTheme } from '../../contexts/ContentThemeContext';
import styled from 'styled-components';
import { media } from 'styleguide/helpers/media';
import { darken } from 'utils/color';
import { WidgetCommonProps } from './interfaces/interfaces';
const Markdown = loadable(() => import(/* webpackChunkName: "markdown" */ 'common/components/Markdown'));
import { contentLinkStyle } from '../General';
import { OutlinedButtonLink } from 'styleguide/components/Buttons/OutlinedButton';
import { LazyImageProps, LazyImage } from 'styleguide/components/LazyImage';
import MediumHeader from 'styleguide/components/Typography/MediumHeader/MediumHeader';
import OddlySubTitle, { OddlySubTitleType } from '../Oddlygood/OddlySubTitle';

const CHESS_SQUARES_IMAGE_ASPECT_RATIO = (500 / 640) * 100;

interface BoxProps {
	order: number;
}

const ChessSquaresWrapper = styled.div`
	width: 100vw;
	margin: 0 calc(50% - 50vw);
	max-width: 1140px;
	padding: 40px 0;

	${media.tablet`
		width: 100%;
		margin: 0;
	`};
`;

const TitleWrapper = styled.div`
	margin: 2em 0px;
`;

const ChessSquaresItemWrapper = styled.div`
	${media.tablet`
		display: flex;
	`};

	& + & {
		margin-top: 20px;

		${media.tablet`
			margin-top: 0;
		`};
	}
`;

const ImageWrapper = styled.div<BoxProps>`
	${media.tablet<BoxProps>`
    order: ${props => props.order};
		width: 50%;
		flex: 1 0 50%;
  `};
`;

const BackgroundImage = styled.div`
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: cover;
	height: 0;
	padding-top: ${CHESS_SQUARES_IMAGE_ASPECT_RATIO}%;
`;

type ContentWrapperProps = ContentTheme & BoxProps;

const ContentWrapper = styled.div<ContentWrapperProps>`
	font-family: ${props => props.theme.fonts.secondary};
	font-size: 15px;
	line-height: 1.33;
	padding: 15px;

	${media.tablet<ContentWrapperProps>`
		padding: 35px;
		width: 50%;
		flex: 0 1 50%;
    order: ${props => props.order};
		display: flex;
		flex-direction: column;
		justify-content: center;
  `};

	${media.desktop`
		font-size: 18px;
		padding: 55px;
	`};

	.outlined-button-link {
		margin: 0 auto;
	}
`;

const WidgetHeader = styled(MediumHeader)`
	margin-top: 0;
	text-align: center;
	color: ${props => props.color};
`;

interface TitleProps {
	isOdd: boolean;
}

type AllTitleProps = ContentTheme & TitleProps;

const Title = styled.h3<AllTitleProps>`
	font-family: ${({ theme }) => theme.fonts.primary};
	font-size: 24px;
	font-weight: 600;

	${({ theme }) => theme.typography.heading};

	color: ${({ color, theme, isOdd }) => {
		if (isOdd) {
			return theme.colors.chessSquareOddHeader || color;
		} else {
			return theme.colors.chessSquareEvenHeader || color;
		}
	}};
	margin: 0;
	line-height: 1;

	${media.desktop`
		font-size: 34px;
	`};
`;

type ContentProps = ContentTheme;

const Content = styled.div<ContentProps>`
	margin: 3px 0 18px;

	a {
		${({ theme, color }) => contentLinkStyle(theme, color)};
	}

	p {
		white-space: pre-wrap;
	}
`;

class ChessSquares extends React.Component<WidgetCommonProps & ChessSquaresConfiguration> {
	public static defaultProps: Pick<ChessSquaresConfiguration, 'firstItemImagePosition'> = {
		firstItemImagePosition: 'left',
	};

	public render() {
		//
		// ODDLYGOOD LAYOUT
		//
		if (this.props.layoutName === ChessSquaresLayoutType.oddlygood) {
			return (
				<ChessSquaresWrapper id={this.props.widgetId} className="checkered-image-wrapper">
					{this.props.title && (
						<TitleWrapper>
							<OddlySubTitle type={OddlySubTitleType.Primary} title={this.props.title} />
						</TitleWrapper>
					)}
					{this.renderItems()}
				</ChessSquaresWrapper>
			);
		}

		//
		// DEFAULT LAYOUT
		//
		return (
			<ChessSquaresWrapper id={this.props.widgetId} className="checkered-image-wrapper">
				{this.props.title && (
					<ContentThemeContext.Consumer>
						{contentTheme => <WidgetHeader {...contentTheme}>{this.props.title}</WidgetHeader>}
					</ContentThemeContext.Consumer>
				)}
				{this.renderItems()}
			</ChessSquaresWrapper>
		);
	}

	private renderItems() {
		const { items } = this.props;
		return items.map((item, index) => this.renderItem(item, index));
	}

	private renderItem = ({ title, content, imageUrl, linkUrl, linkText }: ChessSquaresItem, index: number) => {
		const { firstItemImagePosition } = this.props;
		const isOdd = index % 2 > 0;

		const renderImage = ({ src }: LazyImageProps) => {
			return <BackgroundImage style={{ backgroundImage: `url(${src})` }} />;
		};

		const imageOrder =
			(firstItemImagePosition === 'right' && !isOdd) || (firstItemImagePosition === 'left' && isOdd) ? 1 : 0;
		const contentOrder =
			(firstItemImagePosition === 'right' && !isOdd) || (firstItemImagePosition === 'left' && isOdd) ? 0 : 1;

		const renderDefaultButton = (contentTheme: ContentTheme) => (
			<OutlinedButtonLink
				className="outlined-button-link"
				href={linkUrl}
				color={contentTheme.color}
				hoverColor={darken(contentTheme.color, 5)}
				borderHoverColor={darken(contentTheme.color, 5)}>
				{linkText}
			</OutlinedButtonLink>
		);

		const renderOddlygoodButton = (contentTheme: ContentTheme) => (
			<OutlinedButtonLink buttonStyle="secondary" className="outlined-button-link" href={linkUrl}>
				{linkText}
			</OutlinedButtonLink>
		);

		return (
			<ContentThemeContext.Consumer key={`checkered-image-item-${index}`}>
				{contentTheme => (
					<ChessSquaresItemWrapper>
						<ImageWrapper order={imageOrder}>
							<LazyImage src={`${imageUrl}/570x445-chess-square`} render={renderImage} />
						</ImageWrapper>
						<ContentWrapper {...contentTheme} order={contentOrder}>
							<Title {...contentTheme} isOdd={isOdd}>
								{title}
							</Title>
							<Content color={contentTheme.color}>
								<Markdown source={content} />
							</Content>
							{linkUrl &&
								linkText &&
								this.props.layoutName === ChessSquaresLayoutType.oddlygood &&
								renderOddlygoodButton(contentTheme)}
							{linkUrl &&
								linkText &&
								this.props.layoutName !== ChessSquaresLayoutType.oddlygood &&
								renderDefaultButton(contentTheme)}
						</ContentWrapper>
					</ChessSquaresItemWrapper>
				)}
			</ContentThemeContext.Consumer>
		);
	};
}

export default ChessSquares;
