import MarkdownIt from 'markdown-it';
import Token from 'markdown-it/lib/token';
function addLinkPrefix(link: string, prefix: string): string {
	if (prefix.lastIndexOf('/') === prefix.length - 1) {
		return `${prefix.substr(0, prefix.length - 1)}${link}`;
	}
	return `${prefix}${link}`;
}
function processToken(token: Token, prefix: string) {
	if (token.type === 'link_open') {
		const href = token.attrGet('href');
		const linkFilters = ['http', 'mailto:', 'tel:', 'sms:'];
		if (href && linkFilters.findIndex(filter => href.indexOf(filter) === 0) < 0) {
			token.attrSet('href', addLinkPrefix(href, prefix));
		}
	}
	if (token.children && token.children.length > 0) {
		for (const t of token.children) {
			processToken(t, prefix);
		}
	}
}

export default function LinkPrefixerPlugin(md: MarkdownIt, ...params: any[]) {
	if (params.length !== 1 || typeof params[0] !== 'string') {
		throw new Error('Plugin expects a single string parameter (link prefix)');
	}
	md.core.ruler.push('link-prefix', state => {
		for (const token of state.tokens) {
			processToken(token, params[0]);
		}
	});
}
