import * as React from 'react';
import styled from 'styled-components';
import { media } from '../../../helpers';

const IconList = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: -6px;
	width: calc(100% + 12px);

	${media.tablet`
    margin-top: 25px;
  `};

	& > a,
	& > span {
		margin: 5px;
		width: 149px;
		width: calc(50% - 12px);

		${media.tablet`
      width: calc(100% / 3 - 12px);
    `};

		${media.desktop`
      max-width: 170px;
      flex: 1 1 149px;
      flex: 1 1 calc(100% / 6 - 12px);
    `};

		${media.desktop1440`
      flex: 1 1 170px;
      flex: 1 1 calc(100% / 6 - 12px);
    `};
	}
`;

const OutlinedIconLinkList: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ className, children, ...rest }) => {
	return (
		<IconList className={className} {...rest}>
			{children}
		</IconList>
	);
};

export default OutlinedIconLinkList;
