import * as React from 'react';
import styled from 'styled-components';
import LinkElement from 'styleguide/components/Links/HighlightedLink/HighlightedLink';
import { HighlightedLinkConfiguration, HighlightedLinkWidgetType } from './interfaces/HighlightedLink';
import injectRoutable, { InjectedRoutableProps } from '../Routing/Routable';
import HighlightedLinkCarousel from './HighlightedLinkCarousel';
import HighlightedLinkSection from '../HighlightedLinkSection/HighlightedLinkSection';
import { WidgetCommonProps } from './interfaces/interfaces';
import { useIntl } from 'react-intl';

const HighlightedLinkWidgetWrapper = styled.section`
	> h2 {
		text-align: center;
	}
`;

const HighlightedLink: React.FC<WidgetCommonProps & HighlightedLinkConfiguration & InjectedRoutableProps> = props => {
	const intl = useIntl();
	const { widgetId, type, highlightedLinks, onLinkClick } = props;

	const renderLinks = () => {
		if (type === HighlightedLinkWidgetType.carousel) {
			return (
				<HighlightedLinkCarousel
					id={widgetId}
					title={intl.formatMessage({ id: 'widget_highlighted_link_recipes_from_seasonal_ingredients' })}
					links={highlightedLinks}
				/>
			);
		}
		return (
			<HighlightedLinkSection id={widgetId}>
				{highlightedLinks.map((g, i) => (
					<LinkElement key={i} title={g.title} href={g.url} onClick={onLinkClick} target="" intense={g.isIntense}>
						{g.title}
					</LinkElement>
				))}
			</HighlightedLinkSection>
		);
	};

	return <HighlightedLinkWidgetWrapper>{renderLinks()}</HighlightedLinkWidgetWrapper>;
};

export default injectRoutable(HighlightedLink);
