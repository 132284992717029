import * as React from 'react';
import { CmsContentAuthor } from '../../../interfaces';
import styled from 'styled-components';
import Image from 'styleguide/components/PicturesAndVideos/Image/Image';
import { media } from 'styleguide/helpers/media';

const ArticleAuthorWrapper = styled.div``;

const Author = styled.div`
	padding-bottom: 20px;

	img {
		width: 64px;
		height: 64px;
		border-radius: 50%;
	}

	p {
		margin: 0;
		line-height: 1.25;
		font-size: 16px;

		&.author {
			font-weight: 600;
		}
	}
`;

const Intro = styled.p`
	border-top: 1px solid ${props => props.theme.colors.border};
	border-bottom: 1px solid ${props => props.theme.colors.border};
	font-size: 13px;
	margin: 0 0 -1px;
	padding: 10px 0;
	white-space: pre-wrap;

	${media.desktop`
  	padding: 20px 0;
  `};
`;

const ArticleAuthor: React.FC<CmsContentAuthor> = ({ name, title, image, intro }) => {
	return (
		<ArticleAuthorWrapper className="article-author">
			<Author>
				<Image src={`${image}/64x64-article-page-author`} />
				<p className="author">{name}</p>
				<p>{title}</p>
			</Author>
			{intro && <Intro>{intro}</Intro>}
		</ArticleAuthorWrapper>
	);
};

export default ArticleAuthor;
