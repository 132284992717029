import * as React from 'react';
import styled, { css } from 'styled-components';
import { media } from 'styleguide/helpers/media';
import { ImagesInContentConfiguration } from './interfaces/ImagesInContent';
import { WIDGET_SPACING_MEDIUM, WIDGET_SPACING_LARGE } from './constants';
import { WidgetCommonProps } from './interfaces/interfaces';
import { LazyImageProps, LazyImage } from 'styleguide/components/LazyImage';
import { ContentThemeContext } from 'common/contexts/ContentThemeContext';

const SINGLE_IMAGE_MAX_WIDTH = 600;

interface ImageWrapperProps {
	imageCount: number;
	linkUrl: string;
	layoutName: string;
}

const getWrapperHeight = (layout?: string) => {
	switch (layout) {
		case 'ContentWidth':
			return '510px';
		case 'VerticalImages':
			return '710px';
		default:
			return '380px';
	}
};

const ImagesInContentWrapper = styled.div`
	margin: ${WIDGET_SPACING_MEDIUM}px auto;
	width: 100%;

	${media.tablet`
		display: flex;
		justify-content: center;
	`};

	${media.desktop`
		margin: ${WIDGET_SPACING_LARGE}px auto;
	`};
`;

const ImageWithTitleWrapper = styled.div`
	width: 100%;
`;

interface ImageTitleProps {
	themeColor?: string;
}

const ImageTitle = styled.div<ImageTitleProps>`
	margin-top: 0.25em;
	${({ themeColor }) => {
		return css`
			color: ${themeColor};
		`;
	}}
`;

const ImageWrapper = styled.div<ImageWrapperProps>`
	flex: 1;
	height: 235px;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: cover;
	cursor: ${props => (props.linkUrl ? 'pointer' : 'auto')};

	${media.tablet<ImageWrapperProps>`
		${({ imageCount, layoutName }) => {
			if (imageCount < 2) {
				return css`
					max-width: ${SINGLE_IMAGE_MAX_WIDTH}px;
					height: ${getWrapperHeight(layoutName)};
				`;
			}
			return null;
		}};
	`};

	${media.desktop<ImageWrapperProps>`
		${({ layoutName }) => {
			return css`
				height: ${getWrapperHeight(layoutName)};
			`;
		}}`};

	&.images-in-content__image + .images-in-content__image {
		margin-top: 10px;

		${media.tablet<ImageWrapperProps>`
			margin-top: 0;
			margin-left: ${props => (props.imageCount > 2 ? '20px' : '10px')};
		`};
	}
`;

const ImageWrapperFullWidth = styled.div<ImageWrapperProps>`
	flex: 1;
	height: 235px;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: cover;
	cursor: ${props => (props.linkUrl ? 'pointer' : 'auto')};
	max-width: 100%;

	${media.tablet<ImageWrapperProps>`
		${({ layoutName }) => {
			return css`
				height: ${getWrapperHeight(layoutName)};
			`;
		}};
	`};

	${media.desktop<ImageWrapperProps>`
		${({ layoutName }) => {
			return css`
				height: ${getWrapperHeight(layoutName)};
			`;
		}}`};

	&.images-in-content__image + .images-in-content__image {
		margin-top: 10px;

		${media.tablet<ImageWrapperProps>`
			margin-top: 0;
			margin-left: ${props => (props.imageCount > 2 ? '20px' : '10px')};
		`};
	}
`;

const getPrefix = (layoutName?: string) => {
	switch (layoutName) {
		case 'PreserveRatio':
			return '570x380-images-in-content-fit';
		case 'VerticalImages':
			return '570x710-images-in-content';
		case 'ContentWidth':
			return '1440x1080-cms-content-default-hero';
		default:
			return '570x380-images-in-content';
	}
};

const ImagesInContent: React.FC<WidgetCommonProps & ImagesInContentConfiguration> = ({
	widgetId,
	items,
	layoutName,
}) => {
	const imageCount = items.length;

	if (imageCount === 0) {
		return null;
	}

	const preset = getPrefix(layoutName);

	return (
		<ImagesInContentWrapper id={widgetId} className="images-in-content-wrapper">
			{items.map(({ imageUrl, linkUrl, alt }, index) => {
				const renderImage = ({ src }: LazyImageProps) => {
					if (layoutName === 'ContentWidth') {
						return (
							<ImageWrapperFullWidth
								className="images-in-content__image"
								style={{ backgroundImage: `url(${src})` }}
								title={alt}
								imageCount={imageCount}
								linkUrl={linkUrl}
								layoutName={layoutName}
								onClick={linkUrl ? imageClick.bind(undefined, linkUrl) : undefined}
							/>
						);
					} else {
						return (
							<ImageWrapper
								className="images-in-content__image"
								style={{ backgroundImage: `url(${src})` }}
								title={alt}
								imageCount={imageCount}
								linkUrl={linkUrl}
								layoutName={layoutName}
								onClick={linkUrl ? imageClick.bind(undefined, linkUrl) : undefined}
							/>
						);
					}
				};
				if (layoutName === 'ContentWidth') {
					return (
						<ImageWithTitleWrapper key={`images-${index}`}>
							<LazyImage src={`${imageUrl}/${preset}`} render={renderImage} />
							<ContentThemeContext.Consumer>
								{contentTheme => <ImageTitle themeColor={contentTheme.color}>{alt}</ImageTitle>}
							</ContentThemeContext.Consumer>
						</ImageWithTitleWrapper>
					);
				} else {
					return <LazyImage key={`images-${index}`} src={`${imageUrl}/${preset}`} render={renderImage} />;
				}
			})}
		</ImagesInContentWrapper>
	);
};

function imageClick(linkUrl: string, e: React.MouseEvent<HTMLDivElement>) {
	e.stopPropagation();
	window.location.href = linkUrl;
}

export default ImagesInContent;
