import * as React from 'react';
import MarkdownIt from 'markdown-it';
import LinkPrefixerPlugin from './link-prefixer';
interface MarkdownProps {
	source: string;
	tagName?: keyof JSX.IntrinsicElements;
	className?: string;
	escapeHtml?: boolean;
	relativeUrlPrefix?: string;
}
interface MarkdownState {
	output: string;
}
class Markdown extends React.PureComponent<MarkdownProps, MarkdownState> {
	private md: MarkdownIt;
	constructor(props: MarkdownProps) {
		super(props);
		this.md = new MarkdownIt({ html: !!props.escapeHtml } as MarkdownIt.Options);
		if (props.relativeUrlPrefix) {
			this.md.use(LinkPrefixerPlugin, props.relativeUrlPrefix);
		}
		this.state = {
			output: this.parse(),
		};
	}
	public componentDidUpdate(prevProps: MarkdownProps) {
		if (this.props.source !== prevProps.source) {
			this.setState({ output: this.parse() });
		}
	}
	public render() {
		const { tagName, className } = this.props;
		const Container = tagName || 'div';
		return <Container dangerouslySetInnerHTML={{ __html: this.state.output }} className={className} />;
	}
	private parse() {
		return this.md.render(this.props.source || '');
	}
}
export default Markdown;
