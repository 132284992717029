import * as React from 'react';
import { VideoListConfiguration, VideoListItem } from './interfaces/VideoList';
import styled from 'styled-components';
import { media } from 'styleguide/helpers/media';
import MediumHeader from 'styleguide/components/Typography/MediumHeader/MediumHeader';
import LightboxMedia from 'styleguide/components/PicturesAndVideos/LightboxMedia';
import { WIDGET_SPACING_MEDIUM, WIDGET_SPACING_LARGE } from './constants';
import { generate } from 'shortid';
import { WidgetCommonProps } from './interfaces/interfaces';
import { VideoSource } from './interfaces/EmbeddedVideo';
import Markdown from '../Markdown';

const VideoListWrapper = styled.article`
	margin: ${WIDGET_SPACING_MEDIUM}px auto;

	${media.desktop`
    margin: ${WIDGET_SPACING_LARGE}px auto;
  `};

	.medium-header {
		margin: 0 auto 40px;
		text-align: center;
	}
`;

interface ListProps {
	numberOfVideos: number;
}

const List = styled.ul<ListProps>`
	margin: 0;
	padding: 0;
	list-style: none;

	${media.tablet`
    margin: -20px -10px;
  `};

	${media.desktop<ListProps>`
  	margin: -20px -10px;
	  display: flex;
    flex-wrap: wrap;
    justify-content: ${props => (props.numberOfVideos > 1 ? 'flex-start' : 'space-around')};
  `};
`;

const ListItem = styled.li`
	margin: 10px 0;

	${media.tablet`
    display: flex;
  `};

	${media.desktop`
		flex: 0 1 auto;
    flex: 0 1 calc(50% - 20px);
		width: calc(50% - 20px);
  	margin: 20px 10px;
  `};
`;

const LargeListItem = styled.li<{
	startBackgroundHighlight?: 'first' | 'second';
}>`
	margin: 10px 0;
	padding: 5px 5px 0 5px;

	${media.tablet`
    display: flex;
		padding: 16px;
  `};

	${media.desktop`
		flex: 0 1 auto;
    flex: 0 1 calc(100% - 20px);
		width: calc(100% - 20px);
  	margin: 20px 10px;
  `};

	${({ startBackgroundHighlight, theme }) =>
		(startBackgroundHighlight === 'first' &&
			`
			&:nth-child(odd) {
				background-color: ${theme.colors.contentSectionHiglighted};
			}
		`) ||
		(startBackgroundHighlight === 'second' &&
			`
			&:nth-child(even) {
				background-color: ${theme.colors.contentSectionHiglighted};
			}
			`)};
`;

const VideoWrapper = styled.div`
	width: 100%;

	${media.tablet`
  	flex: 0 0 50%;
    width: 50%;
  `};
`;

const Video = styled.div`
	background-position: 50% 50%;
	background-size: 140%;
	background-repeat: no-repeat;
	border-radius: 2px;
	position: relative;
	cursor: pointer;
	width: 100%;
	height: 0;
	padding-top: 56.25%; /* 16/9 */

	&:hover {
		& > div {
			opacity: 0.8;
		}
	}

	&:after {
		content: '';
		border-style: solid;
		border-width: 10px 0 10px 16px;
		border-color: transparent transparent transparent white;
		width: 0;
		height: 0;
		top: 50%;
		left: 50%;
		position: absolute;
		transform: translate(-50%, -50%);
	}

	& > div {
		border-radius: 50%;
		color: #ffffff;
		font-size: 15px;
		height: 80px;
		text-align: center;
		text-indent: 0.1em;
		transition: opacity 200ms ease-out;
		width: 80px;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		opacity: 0.5;
		background: ${({ theme }) => theme.colors.buttonTertiary};
		box-sizing: border-box;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	}
`;

const VideoDetails = styled.div<{ isLargeLayout: boolean }>`
	padding: 10px 20px;

	${media.tablet<{ isLargeLayout: boolean }>`
  	flex: 0 0 50%;
    padding: 10px 0 10px 30px;

		${({ isLargeLayout }) =>
			isLargeLayout &&
			`
				display: flex;
				flex-direction: column;
				justify-content: center;
				padding: 10px 110px 10px 30px;
		`};

  `};
`;
// padding: ${({ isLargeLayout }) => (isLargeLayout ? `80px 110px` : `10px 0`)} 10px 30px;
const VideoTitle = styled.h4`
	${props => props.theme.typography.heading};
	font-size: 18px;
	line-height: 21px;
	letter-spacing: -0.2px;
	color: ${props => props.theme.colors.brandPrimary};
	margin: 0 0 5px;

	${media.tablet`
    font-size: 24px;
    line-height: 26px;
  `};
`;

const VideoDescription = styled.p`
	font-size: 15px;
	line-height: 1.33;
	color: ${props => props.theme.colors.bodyText};
	margin: 0;

	${media.tablet`
  	font-size: 18px;
  `};
`;

interface VideoListState {
	currentVideoId?: string | null;
}

class VideoList extends React.Component<WidgetCommonProps & VideoListConfiguration, VideoListState> {
	public state: VideoListState = {
		currentVideoId: null,
	};

	public render() {
		const { widgetId, videos, title, layoutName, startBackgroundHighlight } = this.props;
		const { currentVideoId } = this.state;

		if (videos.length < 1) {
			return null;
		}

		return (
			<VideoListWrapper id={widgetId}>
				{title && (
					<header>
						<MediumHeader tagName="h3" className="medium-header">
							{title}
						</MediumHeader>
					</header>
				)}
				<List numberOfVideos={videos.length}>
					{videos.map(video => this.renderVideo({ layoutName, startBackgroundHighlight, ...video }))}
				</List>
				<LightboxMedia
					medias={videos.map(v => {
						switch (v.sourceType) {
							case VideoSource.DreamBroker:
								return {
									src: `https://dreambroker.com/channel/${v.channelId}/iframe/${v.videoId}/`,
									type: 'video',
								};

							case VideoSource.Vimeo:
								return {
									src: `https://player.vimeo.com/video/${v.videoId}${v.videoHash ? `?h=${v.videoHash}` : ''}`,
									type: 'video',
								};
							default:
								return {
									src: v.videoId,
									type: 'video',
								};
						}
					})}
					isOpen={Boolean(currentVideoId)}
					onClose={this.closeVideo}
					initialItem={this.getVideoIndex()}
				/>
			</VideoListWrapper>
		);
	}

	private getVideoIndex = () => {
		const { currentVideoId } = this.state;
		const { videos } = this.props;
		const index = videos.findIndex(v => v.videoId === currentVideoId);
		return index >= 0 ? index : 0;
	};

	private openVideo = (videoId: string) => {
		this.setState({ currentVideoId: videoId });
	};

	private closeVideo = () => {
		this.setState({ currentVideoId: null });
	};

	private renderVideo({
		videoId,
		title,
		description,
		sourceType,
		channelId,
		thumbnailImageUrl,
		layoutName,
		startBackgroundHighlight,
	}: VideoListItem & Pick<VideoListConfiguration, 'layoutName' | 'startBackgroundHighlight'>) {
		const videoThumbnail =
			sourceType === VideoSource.DreamBroker
				? `https://www.dreambroker.com/channel/${channelId}/${videoId}/get/poster/480x360.jpg`
				: `https://i.ytimg.com/vi/${videoId}/hqdefault.jpg`;
		const backgroundImage = {
			backgroundImage: `url(${thumbnailImageUrl ?? videoThumbnail})`,
		};

		const onClick = () => {
			this.openVideo(videoId);
		};

		return (
			<>
				{layoutName === 'large' ? (
					<LargeListItem key={generate()} startBackgroundHighlight={startBackgroundHighlight}>
						<VideoWrapper>
							<Video style={backgroundImage} onClick={onClick}>
								<div />
							</Video>
						</VideoWrapper>
						<VideoDetails isLargeLayout={true}>
							<VideoTitle>{title}</VideoTitle>
							<VideoDescription>
								<Markdown source={description} escapeHtml={true} />
							</VideoDescription>
						</VideoDetails>
					</LargeListItem>
				) : (
					<ListItem key={generate()}>
						<VideoWrapper>
							<Video style={backgroundImage} onClick={onClick}>
								<div />
							</Video>
						</VideoWrapper>
						<VideoDetails isLargeLayout={false}>
							<VideoTitle>{title}</VideoTitle>
							<VideoDescription>
								<Markdown source={description} escapeHtml={true} />
							</VideoDescription>
						</VideoDetails>
					</ListItem>
				)}
			</>
		);
	}
}

export default VideoList;
