import { WidgetConfiguration } from './interfaces';

export enum VideoSource {
	YouTube = 'Youtube',
	DreamBroker = 'DreamBroker',
	Vimeo = 'Vimeo',
}
export interface EmbeddedVideoConfiguration {
	videoId: string;
	sourceType: VideoSource;
	channelId: string | undefined;
	videoHash: string | undefined;
}

export interface EmbeddedVideoWidget extends WidgetConfiguration {
	widgetName: 'EmbeddedVideoFrodget';
	configuration: EmbeddedVideoConfiguration;
}
