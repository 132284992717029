import * as React from 'react';
import { LargeBannerWithImageConfiguration, LargeBannerWithImageLayouts } from './interfaces/LargeBannerWithImage';
import styled, { ThemeContext } from 'styled-components';
import { media, mediaBreakpoints } from 'styleguide/helpers/media';
import { ContentThemeContext } from '../../contexts/ContentThemeContext';
import { WIDGET_SPACING_MEDIUM, WIDGET_SPACING_LARGE } from './constants';
import { WidgetCommonProps } from './interfaces/interfaces';
import { OutlinedButtonLink } from 'styleguide/components/Buttons/OutlinedButton';
import { LazyImageProps, LazyImage } from 'styleguide/components/LazyImage';
import { TEXT_WIDTH_NARROW, TEXT_WIDTH } from './constants';
import OddlySubTitle, { OddlySubTitleType } from '../Oddlygood/OddlySubTitle';

interface LargeBannerWithImageLayoutProps {
	layoutName?: LargeBannerWithImageLayouts;
	imageUrl: string;
}

const LargeBannerWithImageWrapper = styled.div`
	width: 100vw;
	margin: ${WIDGET_SPACING_MEDIUM}px 0px;

	${media.phone`
		margin: ${WIDGET_SPACING_MEDIUM}px ${props => props.theme.grid.gutterInPx(-2)};
	`};

	${media.tablet`
		margin: 0 -40px;
	`};

	${media.desktop`
    display: flex;
    align-items: stretch;
    width: 100%;
		margin: ${WIDGET_SPACING_LARGE}px auto;
  `};
`;

const LargeBannerWithImageImage = styled.div<LargeBannerWithImageLayoutProps>`
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: cover;
	background-image: url(${props => props.imageUrl + '/800x500-large-banner-with-image'});
	flex: 2 0 auto;
	width: 100%;
	height: 230px;

	${media.tablet<LargeBannerWithImageLayoutProps>`
    height: 350px;
  `};

	${media.desktop<LargeBannerWithImageLayoutProps>`
    width: calc(100% / 3 * 2);
  	min-height: 500px;
    height: auto;
    border-radius: 2px;
  	order: ${props => (props.layoutName === 'ImageRight' ? 1 : 0)};
  `};
`;

interface LargeBannerWithImageContentProps {
	themeColor: string;
}

const LargeBannerWithImageContent = styled.div<LargeBannerWithImageContentProps>`
	font-family: ${props => props.theme.fonts.secondary};
	background: ${props => props.themeColor};
	flex: 1 1 auto;
	width: 100%;
	color: ${props => props.theme.colors.white};
	padding: 30px 20px;

	${media.tablet`
    padding: 50px;
  `};

	${media.desktop`
    width: calc(100% / 3);
    margin: 20px 0;
    border-radius: 0 2px 2px 0;
  `};

	.outlined-button-link {
		margin: 20px 0 0;
	}
`;

const LargeBannerWithImageTitle = styled.h3`
	${({ theme }) => theme.typography.heading};
	font-size: 24px;
	line-height: 1;
	letter-spacing: -0.3px;
	margin: 0 0 10px;

	${media.desktop`
    font-size: 36px;
    letter-spacing: -0.5px;
  `};
`;

const LargeBannerWithImageLead = styled.p`
	font-size: 15px;
	line-height: 20px;
	margin: 0;

	${media.desktop`
  	font-size: 18px;
    line-height: 24px;
  `};
`;

// ==============================
// Oddlygood styles
// ==============================
interface OddlygoodImageWrapperProps {
	pullup?: boolean;
}

// width: 100vw;
// argin: ${WIDGET_SPACING_MEDIUM}px ${props => props.theme.grid.gutterInPx(-1)};

const OddlygoodImageWrapper = styled.div<OddlygoodImageWrapperProps>`
	${media.phone`
		margin: ${props => props.theme.grid.gutterInPx(2)};
	`};

	${media.tablet`
		width: calc(100% - ${props => props.theme.grid.gutterInPx(2)});
		padding: ${props => props.theme.grid.gutterInPx(2)};
	`};

	${media.desktop<OddlygoodImageWrapperProps>`
		padding: 0px;
    max-width: 600px;
		margin: ${WIDGET_SPACING_LARGE}px auto;
		${({ pullup }) => pullup && `margin-top: -40px;`}
  `};
`;

const OddlygoodSmallImage = styled.div<LargeBannerWithImageLayoutProps>`
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: cover;
	background-image: url(${props => props.imageUrl + '/800x500-large-banner-with-image'});
	flex: 2 0 auto;
	width: 100%;
	height: 230px;

	${media.tablet<LargeBannerWithImageLayoutProps>`
    height: 350px;
  `};

	${media.desktop<LargeBannerWithImageLayoutProps>`
    max-width: 600px;
    border-radius: 2px;
  `};
`;

const OddlygoodLargeImage = styled.div<LargeBannerWithImageLayoutProps>`
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: cover;
	background-image: url(${props => props.imageUrl + '/800x500-large-banner-with-image'});
	flex: 2 0 auto;
	width: 100%;
	height: 230px;

	${media.phone`
		width: 100vw;
		margin-left: ${props => props.theme.grid.gutterInPx(-4)}
	`};

	${media.tablet<LargeBannerWithImageLayoutProps>`
		margin-left: ${props => props.theme.grid.gutterInPx(-6)};
		height: 350px;
  `};

	${media.desktop<LargeBannerWithImageLayoutProps>`
		background-image: url(${props => props.imageUrl});
		border-radius: 0px;
		min-height: 500px;
		margin-left: calc((100vw - 600px) / -2 - 9px);
  `};
`;

const OddlySubTitleWrapper = styled.div`
	position: relative;
	top: -35px;
	width: 80%;
	margin-left: auto;
	margin-right: auto;

	${media.tablet`
		width: inhreit;
		position: relative;
		top: -35px;
	`};

	${media.desktop`
		position: relative;
		top: -35px;
  `};
`;

interface OddlyTextWrapperProps {
	centered?: boolean;
	needMargin?: boolean;
}

const OddlyTextWrapper = styled.div<OddlyTextWrapperProps>`
	display: flex;
	margin-bottom: 1em;

	> a {
		margin: 0 auto;
	}

	${({ centered }) =>
		centered &&
		`
	@media (max-width: ${mediaBreakpoints.phone660}px) {
		display: flex;
		justify-content: space-around;
	}
	`}

	${({ needMargin }) =>
		needMargin &&
		`
		margin-top: 2em;
	`}

	${media.tablet`
		max-width: ${TEXT_WIDTH_NARROW}px;
		margin-left: auto;
		margin-right: auto;
	`};

	${media.desktop`
		padding: 0 4em;
		margin-left: inherit;
		margin-right: inherit;
	`};

	${media.desktop1440`
    max-width: ${TEXT_WIDTH}px;
  `};
`;

// ==============================
// Component
// ==============================

const LargeBannerWithImage: React.FC<WidgetCommonProps & LargeBannerWithImageConfiguration> = ({
	widgetId,
	title,
	lead,
	imageUrl,
	linkUrl,
	linkText,
	layoutName,
	backgroundColor,
}) => {
	const theme = React.useContext(ThemeContext);

	const renderOddlygoodSmall = (pullup: boolean) => {
		const renderSmallImage = ({ src }: LazyImageProps) => {
			return <OddlygoodSmallImage imageUrl={src as string} layoutName={layoutName} />;
		};

		return (
			<OddlygoodImageWrapper id={widgetId} pullup={pullup}>
				<LazyImage src={imageUrl} render={renderSmallImage} />
				{title && (
					<OddlySubTitleWrapper>
						<OddlySubTitle title={title} type={OddlySubTitleType.Extra2} />
					</OddlySubTitleWrapper>
				)}
				<OddlyTextWrapper needMargin={!title}>{lead}</OddlyTextWrapper>
				{linkUrl && linkText && (
					<OddlyTextWrapper centered={true}>
						<OutlinedButtonLink buttonStyle="secondary" href={linkUrl} size={{ default: 'medium', desktop: 'large' }}>
							{linkText}
						</OutlinedButtonLink>
					</OddlyTextWrapper>
				)}
			</OddlygoodImageWrapper>
		);
	};

	const renderOddlygoodLarge = () => {
		const renderLargeImage = ({ src }: LazyImageProps) => {
			return <OddlygoodLargeImage imageUrl={src as string} layoutName={layoutName} />;
		};

		return (
			<OddlygoodImageWrapper id={widgetId} pullup={false}>
				<LazyImage src={imageUrl} render={renderLargeImage} />
				<OddlyTextWrapper>
					<OddlySubTitle title={title} type={OddlySubTitleType.Simple} />
				</OddlyTextWrapper>
				{lead && <OddlyTextWrapper>{lead}</OddlyTextWrapper>}
				{linkUrl && linkText && (
					<OddlyTextWrapper centered={true}>
						<OutlinedButtonLink buttonStyle="secondary" href={linkUrl} size={{ default: 'medium', desktop: 'large' }}>
							{linkText}
						</OutlinedButtonLink>
					</OddlyTextWrapper>
				)}
			</OddlygoodImageWrapper>
		);
	};

	if (layoutName === 'OddlygoodSmallPullup') {
		return renderOddlygoodSmall(true);
	} else if (layoutName === 'OddlygoodSmall') {
		return renderOddlygoodSmall(false);
	} else if (layoutName === 'OddlygoodBig') {
		return renderOddlygoodLarge();
	}

	const renderImage = ({ src }: LazyImageProps) => {
		return <LargeBannerWithImageImage imageUrl={src as string} layoutName={layoutName} />;
	};

	return (
		<LargeBannerWithImageWrapper id={widgetId}>
			<LazyImage src={imageUrl} render={renderImage} />
			<ContentThemeContext.Consumer>
				{contentTheme => (
					<LargeBannerWithImageContent themeColor={backgroundColor || contentTheme.color}>
						<LargeBannerWithImageTitle>{title}</LargeBannerWithImageTitle>
						<LargeBannerWithImageLead>{lead}</LargeBannerWithImageLead>
						{linkUrl && linkText && (
							<OutlinedButtonLink
								className="outlined-button-link"
								href={linkUrl}
								color={backgroundColor || contentTheme.color}
								hoverColor={backgroundColor || contentTheme.color}
								backgroundColor={theme.colors.white}
								backgroundHoverColor={theme.colors.buttonOutlineBackgroundHover}
								size={{ default: 'medium', desktop: 'large' }}>
								{linkText}
							</OutlinedButtonLink>
						)}
					</LargeBannerWithImageContent>
				)}
			</ContentThemeContext.Consumer>
		</LargeBannerWithImageWrapper>
	);
};

export default LargeBannerWithImage;
