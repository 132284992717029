import { postJson } from 'utils/service';

async function verifyRecaptcha(response: string): Promise<any> {
	return await postJson(`/api/recaptcha/verify`, response, true);
}

export const recaptchaApi = {
	verifyRecaptcha,
};

export type ReCaptchaApi = typeof recaptchaApi;
