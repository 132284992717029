import * as React from 'react';
import Link from 'common/components/Routing/Link';
import styled from 'styled-components';
import { media } from 'styleguide/helpers/media';
import { RecipeAspectGroupHighlightConfiguration } from 'common/components/Widgets/interfaces/RecipeAspectGroupHighlight';
import { WidgetCommonProps } from './interfaces/interfaces';

const RecipeAspectGroupHighlightWrapper = styled.ul`
	list-style: none;
	margin: 20px 0;
	padding: 0;
	width: 100%;

	${media.desktop`
		display: flex;
	`};
`;

const RecipeAspectGroupLink = styled.li`
	${props => props.theme.typography.heading};
	font-size: 18px;
	line-height: 18px;
	border-top: 1px solid ${props => props.theme.colors.border};
	padding: 14px 20px;

	${media.desktop`
		font-size: 15px;
		line-height: 18px;
		border-top: none;
		padding: 6px 20px;

		&:first-child {
			padding-left: 0;
		}

		& + & {
			border-left: 1px solid ${props => props.theme.colors.border};
		}
	`};

	a {
		color: ${props => props.theme.colors.linkText};
		text-decoration: none;
	}
`;

const RecipeAspectGroupHighlight: React.FC<WidgetCommonProps & RecipeAspectGroupHighlightConfiguration> = ({
	widgetId,
	recipeAspectGroupLinks,
}) => {
	if (!recipeAspectGroupLinks || !recipeAspectGroupLinks.length) {
		return null;
	}
	return (
		<RecipeAspectGroupHighlightWrapper id={widgetId}>
			{recipeAspectGroupLinks.map(l => (
				<RecipeAspectGroupLink key={l.url}>
					<Link to={l.url}>
						<span dangerouslySetInnerHTML={{ __html: l.title }} />
					</Link>
				</RecipeAspectGroupLink>
			))}
		</RecipeAspectGroupHighlightWrapper>
	);
};

export default RecipeAspectGroupHighlight;
