import { State } from 'common/reducers';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import MediumHeader from 'styleguide/components/Typography/MediumHeader';
import SocialLinks from '../SocialLinks/SocialLinks';
import { WidgetCommonProps } from './interfaces/interfaces';
import { ShareContentWidgetConfiguration } from './interfaces/ShareContent';
import { TEXT_WIDTH, TEXT_WIDTH_NARROW, WIDGET_SPACING_SMALL } from './constants';
import { media } from 'styleguide/helpers';

export default function ShareContent({
	title,
	buttonText,
	widgetId,
}: ShareContentWidgetConfiguration & WidgetCommonProps) {
	const { pageTitle, canonicalUrl, shareImage } = useSelector(({ resource }: State) => ({
		canonicalUrl: resource.canonicalUrl,
		pageTitle: resource.titleHtml ?? resource.title ?? '',
		shareImage: resource.heroImageDesktop,
	}));

	return (
		<ShareContainer id={widgetId}>
			{title && <ShareTitle tagName="h2">{title}</ShareTitle>}
			<SocialLinks
				title={pageTitle}
				url={canonicalUrl}
				imageUrl={shareImage}
				shareButtonText={buttonText}
				clearPositioning={true}
			/>
		</ShareContainer>
	);
}

const ShareContainer = styled.div`
	width: 100%;
	max-width: ${TEXT_WIDTH_NARROW}px;
	margin: ${WIDGET_SPACING_SMALL}px auto;
	padding: 0 ${({ theme }) => theme.grid.gutterInPx()};

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;

	${media.tablet`
		padding: 0;
	`};

	${media.desktop1440`
    max-width: ${TEXT_WIDTH}px;
  `};
`;

const ShareTitle = styled(MediumHeader)`
	font-size: 18px;
	${media.tablet`
  font-size: 24px;
	`};
`;
