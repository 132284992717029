import * as React from 'react';
import { useState } from 'react';
import {
	BooleanInputFormField,
	DateSelectionValue,
	FormField,
	FormInputType,
	FormSectionsProps,
	FormValues,
	FormWidget,
	FormWidgetConfiguration,
	FormWidgetSection,
	FormWidgetSectionProps,
	isBooleanInputFormField,
	isDateSelectionInputFormField,
	isOpenInputFormField,
	isSelectionInputFormField,
	OpenInputFormField,
	SelectionInputFormField,
	SubmitSuccessModalProps,
	WithFormContextValues,
} from './interfaces/Form';
import styled, { ThemeContext } from 'styled-components';
import { TEXT_WIDTH, TEXT_WIDTH_NARROW, WIDGET_SPACING_SMALL } from './constants';
import { media, withWindow } from '../../../styleguide/helpers';
import { FormattedMessage, useIntl } from 'react-intl';
import Input, { InputProps } from '../../../styleguide/components/Inputs/Input';
import Dropdown from '../../../styleguide/components/Inputs/Dropdown';
import Checkbox from '../../../styleguide/components/Inputs/Checkbox';
import { Icon } from '../../../utils/import';
import { FieldError, useForm } from 'react-hook-form';
import { Button } from '../../../styleguide/components/Buttons/Button';
import { submitForm } from './services/Form';
import { useSelector } from 'react-redux';
import { getSiteUrlId } from '../App/services';
import { State } from '../../reducers';
import Modal from '../../../styleguide/components/Modals/Modal';
import { formatEventDateWithTimeAndTimeZone } from '../../../pagetypes/Event/utils';
import TextArea from 'styleguide/components/Inputs/TextArea';
import { CalendarPlusIcon, CloseIcon } from 'styleguide/components/Icons';
import { useScript } from 'common/hooks/useScript';
import { localeSelector } from '../../selectors/localeSelector';
import { Locales } from '../../types/locale';
import ReCaptcha from '../ReCaptcha/ReCaptcha';
import { LabeledIconButton } from 'styleguide/components/Buttons/LabeledIconButton';
import { GenericHighlightContainer } from './GenericHighlight';
import ArticleCard from 'styleguide/components/Cards/ArticleCard';
import IconButton from 'styleguide/components/Buttons/IconButton';

export default function Form({
	id,
	icon,
	title,
	ingress,
	sections,
	postFormSubmitButtonTitle,
	postFormSubmitMessage,
	submitDialogSubTitle,
	articles,
	widgetId,
	linkedInAutoFillMap,
	useCaptcha,
	leadSection,
	layoutName,
}: FormWidgetConfiguration & FormWidget) {
	const {
		canSubmit,
		handleSubmit,
		isRecaptchaValid,
		setRecaptchaState,
		shouldShowSuccessModal,
		eventUrl,
		closeSuccessModal,
		register,
		errors,
		expanded,
		setExpanded,
		handleAccordionClick,
	} = useCustomForm({ id, useCaptcha, layoutName });

	const siteKey = withWindow(w => w.reCaptchaSiteKey);

	return (
		<FormContainer>
			<FormHeaderContainer id={widgetId}>
				<Icon iconName={icon} />
				<FormTitle>{title}</FormTitle>
				<FormIngress>{ingress}</FormIngress>
			</FormHeaderContainer>
			{layoutName && layoutName === 'accordion' && (
				<FormAccordionSection leadSection={leadSection} onClick={handleAccordionClick} expanded={expanded} />
			)}
			{linkedInAutoFillMap && <LinkedInAutoFill linkedInAutoFillMap={linkedInAutoFillMap} />}

			<form id={id} onSubmit={handleSubmit}>
				<FormSections
					sections={sections}
					register={register}
					errors={errors}
					expanded={expanded}
					setExpanded={setExpanded}
					layoutName={layoutName}>
					{useCaptcha && (
						<CaptchaContainer>
							<ReCaptcha resultCallback={setRecaptchaState} siteKey={siteKey} expiredCallback={setRecaptchaState} />
						</CaptchaContainer>
					)}
					{postFormSubmitButtonTitle && isRecaptchaValid && (
						<FormButton type="submit" disabled={!canSubmit}>
							{postFormSubmitButtonTitle}
						</FormButton>
					)}
				</FormSections>
			</form>
			{shouldShowSuccessModal && (
				<SubmitSuccessModal
					onClose={closeSuccessModal}
					icon={icon}
					postFormSubmitMessage={postFormSubmitMessage}
					submitDialogSubTitle={submitDialogSubTitle}
					articles={articles}
					eventLink={eventUrl}
				/>
			)}
		</FormContainer>
	);
}

function LinkedInAutoFill({ linkedInAutoFillMap }: Pick<FormWidgetConfiguration, 'linkedInAutoFillMap'>) {
	const lnkdInAutofillSrc = 'https://www.linkedin.com/autofill/js/autofill.js';
	const initProps = {
		src: lnkdInAutofillSrc,
		async: true,
	};
	useScript(initProps);
	const buttonPlaceHolder = React.useRef<HTMLDivElement>(null);
	const mapProps = {
		type: 'IN/Form2',
		ref: buttonPlaceHolder,
		...linkedInAutoFillMap,
	};

	useScript(mapProps);
	return <LinkedInButtonWrapper id="linkedInButtonWrapper" ref={buttonPlaceHolder} />;
}

interface FormAccordionProps {
	expanded: boolean;
	onClick: () => void;
}

function FormAccordionSection({
	leadSection,
	expanded,
	onClick,
}: Pick<FormWidgetConfiguration, 'leadSection'> & FormAccordionProps) {
	const theme = React.useContext(ThemeContext);
	return (
		<FormAccordionSectionContainer>
			{leadSection?.person ? (
				<PersonCard>
					{leadSection.person.image && <PersonImage src={`${leadSection.person.image}/128x148-person-highlight`} />}
					<PersonDetailsWrapper>
						{leadSection.person.name && <AccordionHeader>{leadSection.person.name}</AccordionHeader>}
						{leadSection.person.title && <AccordionSubTitle>{leadSection.person.title}</AccordionSubTitle>}
						{leadSection.person.intro && <AccordionDescription>{leadSection.person.intro}</AccordionDescription>}
					</PersonDetailsWrapper>
				</PersonCard>
			) : (
				<PersonDetailsWrapper>
					{leadSection?.subTitle && <AccordionHeader>{leadSection?.subTitle}</AccordionHeader>}
					{leadSection?.description && <AccordionDescription>{leadSection?.description}</AccordionDescription>}
				</PersonDetailsWrapper>
			)}
			<LabeledIconButton
				icon={<Icon iconName={leadSection?.buttonIcon} />}
				onClick={onClick}
				backgroundColor={theme.colors.buttonTertiary}
				backgroundHoverColor={theme.colors.buttonTertiaryHover}
				size="medium"
				disabled={expanded}
				isFilled>
				{leadSection?.buttonText}
			</LabeledIconButton>
		</FormAccordionSectionContainer>
	);
}

function FormSections({
	sections,
	expanded,
	setExpanded,
	layoutName,
	children,
	...formContext
}: Pick<FormWidgetConfiguration, 'sections'> & WithFormContextValues & FormSectionsProps) {
	return (
		<FormSectionsWrapper expanded={expanded}>
			{sections?.map((section, index, { length }) => (
				<FormSection
					{...section}
					{...formContext}
					key={index}
					index={index}
					layoutName={layoutName}
					setExpanded={setExpanded}>
					{length - 1 === index && children}
				</FormSection>
			))}
		</FormSectionsWrapper>
	);
}

function FormSection({
	translationId,
	fields,
	layoutName,
	index,
	setExpanded,
	children,
	...formContext
}: FormWidgetSection & WithFormContextValues & FormWidgetSectionProps) {
	const closeSection = () => {
		setExpanded(false);
	};
	return (
		<FormSectionContainer>
			{translationId && (
				<FormSectionTitle>
					<FormattedMessage id={translationId} />
				</FormSectionTitle>
			)}
			{layoutName === 'accordion' && index === 0 && (
				<FormSectionClose>
					<FomrSectionCloseButton onClick={closeSection} icon={<CloseIcon />} />
				</FormSectionClose>
			)}
			{fields.map((field, fieldIndex) => (
				<FormSectionField formField={field} {...formContext} key={fieldIndex} />
			))}
			{children}
		</FormSectionContainer>
	);
}

function FormSectionField({ formField, ...formContext }: { formField: FormField } & WithFormContextValues) {
	const { translationId, isMandatory } = formField;
	return (
		<div>
			<FormFieldLabel>
				{translationId && !isBooleanInputFormField(formField) && (
					<FormattedMessage id={translationId} defaultMessage={translationId} />
				)}
				{isMandatory && ' *'}
			</FormFieldLabel>
			<FormInput formField={formField} {...formContext} />
		</div>
	);
}

function FormInput({ formField, ...formContext }: { formField: FormField } & WithFormContextValues) {
	const locale = useSelector((state: State) => localeSelector(state));

	if (isBooleanInputFormField(formField)) {
		return <FormBooleanInput {...formField} {...formContext} />;
	} else if (isOpenInputFormField(formField)) {
		return <FormOpenInput {...formField} {...formContext} />;
	} else if (isSelectionInputFormField(formField)) {
		return <FormSelectionInput {...formField} {...formContext} />;
	} else if (isDateSelectionInputFormField(formField)) {
		const { selectionValues: dateSelectionValues, ...rest } = formField;
		return (
			<FormSelectionInput
				selectionValues={dateSelectionValuesToSelectionValues(dateSelectionValues, locale)}
				{...rest}
				{...formContext}
				inputType={FormInputType.Dropdown}
			/>
		);
	}
	return null;
}

function FormBooleanInput({ id, translationId, register }: BooleanInputFormField & WithFormContextValues) {
	return (
		<Checkbox name={`chk_${id}`} ref={register}>
			<CheckboxLabel>{translationId}</CheckboxLabel>
		</Checkbox>
	);
}

function FormOpenInput({
	inputType,
	id,
	isMandatory,
	maxLength,
	register,
	errors,
}: OpenInputFormField & WithFormContextValues) {
	const validationError = errors[id] && (errors[id] as FieldError).message;
	switch (inputType) {
		case FormInputType.Text:
			return (
				<TextInput
					type="text"
					name={id}
					required={isMandatory}
					maxLength={maxLength}
					register={register}
					validationError={validationError}
				/>
			);
		case FormInputType.PostalCode:
			return (
				<TextInput
					type="text"
					name={id}
					maxLength={maxLength}
					required={isMandatory}
					register={register}
					validationError={validationError}
				/>
			);
		case FormInputType.PhoneNumber:
			const intl = useIntl();
			return (
				<TextInput
					type="tel"
					name={id}
					required={isMandatory}
					maxLength={maxLength}
					register={register}
					validationError={validationError}
					pattern="^(00|\+).*"
					title={intl.formatMessage({ id: 'forms_validatin_phonenumber_pattern' })}
				/>
			);
		case FormInputType.VATCode:
			return (
				<TextInput
					type="text"
					name={id}
					maxLength={maxLength}
					required={isMandatory}
					register={register}
					validationError={validationError}
				/>
			);
		case FormInputType.Email:
			return (
				<TextInput
					type="email"
					name={id}
					required={isMandatory}
					maxLength={maxLength}
					register={register}
					validationError={validationError}
				/>
			);
		case FormInputType.TextArea:
			return (
				<TextAreaInput
					name={id}
					required={isMandatory}
					maxLength={maxLength}
					register={register}
					validationError={validationError}
					rows={5}
				/>
			);
		default:
			return null;
	}
}

function FormSelectionInput({
	inputType,
	id,
	selectionValues,
	isMandatory,
	...formContext
}: SelectionInputFormField & WithFormContextValues) {
	const intl = useIntl();
	const placeholder = intl.formatMessage({ id: 'global_choose' });
	switch (inputType) {
		case FormInputType.Dropdown:
			const selectedValue = selectionValues.length === 1 ? selectionValues[0].value : undefined;
			return (
				<Dropdown
					name={id}
					items={selectionValuesToItems(selectionValues)}
					selectedValue={selectedValue}
					placeholder={placeholder}
					onChange={handleDropdownSelection}
					forwardedRef={
						isMandatory
							? formContext.register({
									validate: value =>
										value === placeholder ? intl.formatMessage({ id: 'forms_validation_required' }) : undefined,
							  })
							: formContext.register
					}
					validationError={formContext.errors[id] && (formContext.errors[id] as FieldError).message}
				/>
			);
		default:
			return null;
	}
}

function TextInputBase({
	className,
	type,
	name,
	register,
	required,
	validationError,
	maxLength,
	pattern,
	title,
}: { className?: string; required?: boolean; maxLength?: number; pattern?: string; title?: string } & InputProps &
	Pick<WithFormContextValues, 'register'>) {
	const intl = useIntl();
	return (
		<Input
			type={type}
			name={name}
			inputClassName={className}
			maxLength={maxLength}
			pattern={pattern}
			ref={required ? register({ required: intl.formatMessage({ id: 'forms_validation_required' }) }) : register}
			validationError={validationError}
			title={title}
		/>
	);
}

function TextAreaBase({
	className,
	name,
	rows,
	register,
	required,
	validationError,
}: { className?: string; required?: boolean; rows?: number } & InputProps & Pick<WithFormContextValues, 'register'>) {
	const intl = useIntl();
	return (
		<TextArea
			inputClassName={className}
			name={name}
			rows={rows}
			ref={required ? register({ required: intl.formatMessage({ id: 'forms_validation_required' }) }) : register}
			validationError={validationError}
		/>
	);
}

function SubmitSuccessModal({
	onClose,
	icon,
	postFormSubmitMessage,
	submitDialogSubTitle,
	articles,
	eventLink,
}: SubmitSuccessModalProps) {
	return (
		<Modal onClose={onClose}>
			<ModalContentContainer>
				<Icon iconName={icon} />
				<ModalTitle>
					<FormattedMessage id="forms_thank_you" />
				</ModalTitle>
				<ModalBodyText>{postFormSubmitMessage}</ModalBodyText>
				{eventLink && (
					<AddToCalendarLink onClick={onClickAddToCalendar} href={eventLink}>
						<CalendarPlusIcon />
						<FormattedMessage id="event_add_to_calendar" />
					</AddToCalendarLink>
				)}
				{submitDialogSubTitle && articles.length > 0 && <ModalSubTitle>{submitDialogSubTitle}</ModalSubTitle>}
				{articles.length > 0 && (
					<GenericHighlightContainer isLeftAligned={articles.length > 1}>
						{articles.map(article => (
							<ArticleCard
								key={article.url}
								{...article}
								truncateLength={85}
								imageSize="small"
								forceSmallTitle={articles.length > 1}
								layoutName={articles.length > 2 ? 'three-column' : 'two-column'}
							/>
						))}
					</GenericHighlightContainer>
				)}
			</ModalContentContainer>
		</Modal>
	);
}

function onClickAddToCalendar(event: React.MouseEvent) {
	event.preventDefault;
	const url = event.currentTarget.getAttribute('href');
	if (!url) {
		return;
	}

	if (window.navigator.msSaveOrOpenBlob && window.Blob) {
		const fileName = 'download.ics';
		const blob = new Blob([url], { type: 'text/calendar;charset=utf-8' });
		// Open/Save link in IE and Edge
		window.navigator.msSaveOrOpenBlob(blob, fileName);
	} else {
		window.open(encodeURI('data:text/calendar;charset=utf8,' + url));
	}
}

function useCustomForm({
	id,
	useCaptcha,
	layoutName,
}: Pick<FormWidgetConfiguration, 'id' | 'useCaptcha' | 'layoutName'>) {
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [shouldShowSuccessModal, setShouldShowSuccessModal] = useState(false);
	const [eventUrl, setEventUrl] = useState<string | undefined>(undefined);
	const { handleSubmit, register, errors } = useForm<FormValues>();
	const reduxState = useSelector(({ app, resource, routing }: State) => ({
		siteUrlId: app.settings ? getSiteUrlId(routing, app.settings.sites) : undefined,
		resourceId: resource.id,
	}));
	const [isRecaptchaValid, setRecaptchaState] = useState<boolean>(!useCaptcha);
	const [expanded, setExpanded] = useState(layoutName !== 'accordion');

	const onSubmit = async (values: FormValues) => {
		if (isRecaptchaValid && reduxState.siteUrlId && reduxState.resourceId) {
			setIsSubmitting(true);
			try {
				const response = await submitForm(reduxState.siteUrlId, reduxState.resourceId, formValuesToApi(id, values));
				setIsSubmitting(false);
				setShouldShowSuccessModal(true);
				if (layoutName === 'accordion') {
					setExpanded(false);
				}
				setEventUrl(response.eventLink);
				return;
			} catch (error) {
				setIsSubmitting(false);
				return undefined;
			}
		}
		return undefined;
	};

	const handleAccordionClick = () => {
		setExpanded(true);
	};

	return {
		canSubmit: !isSubmitting && reduxState.siteUrlId && reduxState.resourceId,
		handleSubmit: handleSubmit(onSubmit),
		isRecaptchaValid,
		setRecaptchaState,
		shouldShowSuccessModal,
		eventUrl,
		closeSuccessModal: () => {
			setShouldShowSuccessModal(false);
			setEventUrl(undefined);
			const form = document.getElementById(id) as HTMLFormElement;
			if (form) {
				form.reset();
			}
		},
		register,
		errors,
		expanded,
		setExpanded,
		handleAccordionClick,
	};
}

function formValuesToApi(formId: string, values: FormValues) {
	return {
		id: formId,
		fields: Object.keys(values).map(id => ({ id, value: values[id] })),
	};
}

function selectionValuesToItems(selectionValues: SelectionInputFormField['selectionValues']) {
	return selectionValues.map(selectionValue => ({
		name: selectionValue.title,
		value: selectionValue.value,
	}));
}

function dateSelectionValuesToSelectionValues(dateSelectionValues: DateSelectionValue[], locale: Locales) {
	return dateSelectionValues.map(({ value, ...eventTime }) => ({
		value,
		title: formatEventDateWithTimeAndTimeZone(eventTime, locale),
	}));
}

function handleDropdownSelection(value: string) {
	// handle selection
}

const FormContainer = styled.div`
	width: 100%;
	max-width: ${TEXT_WIDTH_NARROW}px;
	margin: ${WIDGET_SPACING_SMALL}px auto;
	padding: 0 ${({ theme }) => theme.grid.gutterInPx()};
	color: ${({ theme }) => theme.colors.heading};

	${media.tablet`
		padding: 0;
	`};

	${media.desktop1440`
    max-width: ${TEXT_WIDTH}px;
  `};
`;

const FormHeaderContainer = styled.div`
	text-align: center;
`;

const FormTitle = styled.h2`
	${({ theme }) => theme.typography.heading};
	text-transform: uppercase;
	font-size: 24px;
	line-height: 32px;
	margin: 12px 0;
`;

const FormIngress = styled.h3`
	font-family: ${({ theme }) => theme.fonts.secondary};
	color: ${({ theme }) => theme.colors.bodyText};
	font-weight: 500;
	font-size: 18px;
	line-height: 24px;
	margin: 12px 0;
`;

const FormSectionsWrapper = styled.div<{ expanded: boolean }>`
	display: ${props => (props.expanded ? 'block' : 'none')};
`;

const FormSectionContainer = styled.div`
	padding: ${({ theme }) => `${theme.grid.gutterInPx(4)} ${theme.grid.gutterInPx(4)} ${theme.grid.gutterInPx(5)}`};
	margin: ${({ theme }) => theme.grid.gutterInPx(4)} 0;
	background-color: ${({ theme }) => theme.colors.contentSectionHiglighted};
	border-radius: 10px;
`;

const FormSectionClose = styled.div`
	display: flex;
	justify-content: flex-end;
	margin: -10px 0 0 0;
`;

const FomrSectionCloseButton = styled(IconButton)`
	cursor: pointer;
	border: none;
	background-color: transparent;

	&:hover {
		background-color: transparent;
	}
	&:focus {
		background-color: transparent;
	}
	&:active {
		background-color: transparent;
	}
`;

const FormSectionTitle = styled.h3`
	${({ theme }) => theme.typography.heading};
	font-size: 18px;
	line-height: 26px;
	margin: 0 0 ${({ theme }) => theme.grid.gutterInPx(2)};
`;

const FormFieldLabel = styled.h4`
	${({ theme }) => theme.typography.heading};
	text-transform: uppercase;
	font-size: 14px;
	line-height: 16px;
	margin: ${({ theme }) => theme.grid.gutterInPx(1)} 0;
`;

const FormAccordionSectionContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: ${({ theme }) => `${theme.grid.gutterInPx(4)} ${theme.grid.gutterInPx(4)} ${theme.grid.gutterInPx(5)}`};
	margin: ${({ theme }) => theme.grid.gutterInPx(4)} 0;
	background-color: ${({ theme }) => theme.colors.contentSectionHiglighted};
	border-radius: 10px;
	align-items: center;
	justify-content: space-between;

	${media.tablet`
		padding: ${({ theme }) => `${theme.grid.gutterInPx(4)} ${theme.grid.gutterInPx(4)} ${theme.grid.gutterInPx(5)}`};
		flex-direction: row;
	`};
`;

const PersonCard = styled.div`
	display: flex;
	flex-direction: row;

	margin: 0;
`;

const PersonImage = styled.img`
	width: 128px;
	height: 148px;
	flex: 0 0 128px;

	margin: 0 ${({ theme }) => theme.grid.gutterInPx(3)} 0 0;

	${media.tablet`
		margin: 0 ${({ theme }) => theme.grid.gutterInPx(5)} 0 0;
	`};
`;

const PersonDetailsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin: 0 ${({ theme }) => theme.grid.gutterInPx(3)} ${({ theme }) => theme.grid.gutterInPx(3)} 0;

	${media.tablet`
		margin: 0 ${({ theme }) => theme.grid.gutterInPx(3)} 0 0;
	`};
`;

const AccordionHeader = styled.h3`
	${({ theme }) => theme.typography.heading};
	font-size: 20px;
	line-height: 32px;
	margin: 0;

	${media.desktop`
	font-size: 24px;
`};
`;

const AccordionSubTitle = styled.span`
	font-family: ${({ theme }) => theme.fonts.secondary};
	color: ${({ theme }) => theme.colors.bodyText};
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;

	${media.desktop`
	font-size: 16px;
`};
`;

const AccordionDescription = styled.span`
	font-family: ${({ theme }) => theme.fonts.secondary};
	color: ${({ theme }) => theme.colors.bodyText};
	font-size: 14px;
	line-height: 24px;

	${media.desktop`
	font-size: 16px;
`};
`;

const TextInput = TextInputBase;

const TextAreaInput = TextAreaBase;

const CheckboxLabel = styled.span``;

const FormButton = styled(Button)`
	margin: ${({ theme }) => theme.grid.gutterInPx(3)} 0 0 0;
`;

const ModalContentContainer = styled.div`
	text-align: center;
`;

const LinkedInButtonWrapper = styled.div``;

const ModalTitle = styled(FormTitle)`
	color: ${({ theme }) => theme.colors.heading};
`;

const ModalBodyText = styled(FormIngress)`
	color: ${({ theme }) => theme.colors.bodyText};
`;

const ModalSubTitle = styled.h4`
	${({ theme }) => theme.typography.heading};
	line-height: 1;
	color: ${({ theme }) => theme.colors.heading};
	font-size: 24px;
`;

const AddToCalendarLink = styled.a`
	font-family: ${({ theme }) => theme.fonts.primary};
	color: ${({ theme }) => theme.colors.linkText};
	font-weight: 900;
	font-size: 16px;
	line-height: 22px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	text-decoration: none;

	margin: 30px 0 0 0;

	> svg {
		margin: 0 9px 0 0;
		width: 22px;
		height: 21px;
		vertical-align: sub;

		path {
			stroke: ${({ theme }) => theme.colors.linkText};
		}
	}
`;

const CaptchaContainer = styled.div`
	text-align: center;
	> div {
		display: inline-block;
	}
`;
