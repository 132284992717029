import * as React from 'react';
import { LazyImage } from 'styleguide/components/LazyImage';
import styled from 'styled-components';
import HighlightedLink from 'styleguide/components/Links/HighlightedLink/HighlightedLink';

const LinkWrapper = styled.div`
	display: block;
	width: 100%;
	text-align: center;
	overflow: hidden;
`;

const ImageWrapper = styled.a`
	display: block;

	> img {
		width: auto;
		max-width: 100%;
	}
`;

export interface LargeImageLinkProps {
	url: string;
	imgSrc: string;
}

const LargeImageLink: React.FC<LargeImageLinkProps> = props => {
	const { imgSrc, children, url } = props;

	return (
		<LinkWrapper>
			<ImageWrapper href={url}>
				<LazyImage src={imgSrc} alt="" />
			</ImageWrapper>
			<HighlightedLink intense={true} href={url}>
				{children}
			</HighlightedLink>
		</LinkWrapper>
	);
};

export default LargeImageLink;
