import * as React from 'react';
import OutlinedButtonLink from 'styleguide/components/Buttons/OutlinedButton/OutlinedButtonLink';
import MediumHeader from 'styleguide/components/Typography/MediumHeader/MediumHeader';
import RecipeCard from 'styleguide/components/Cards/RecipeCard/RecipeCard';
import RecipeCardGrid from 'styleguide/components/Cards/RecipeCardGrid/RecipeCardGrid';
import styled, { css } from 'styled-components';
import { media } from 'styleguide/helpers/media';
import {
	RecipeHighlightConfiguration,
	RecipeHighlightRecipe,
	recipeHighlightLayouts,
} from './interfaces/RecipeHighlight';
import { WIDGET_SPACING_MEDIUM, WIDGET_SPACING_LARGE } from './constants';
import { contentWrapperStyles } from '../General';
import injectRoutable, { InjectedRoutableProps } from '../Routing/Routable';
import WidgetText from './components/WidgetText';
import { WidgetCommonProps } from './interfaces/interfaces';
import { generate } from 'shortid';
import { convertLineBreaks } from 'utils/strings';
import { ContentThemeContext } from 'common/contexts/ContentThemeContext';

interface LayoutProps {
	layout: recipeHighlightLayouts;
}

const RecipeHighlightContentWrapper = styled.section<LayoutProps>`
	${({ theme }) => contentWrapperStyles(theme)};
	margin: ${WIDGET_SPACING_MEDIUM}px auto;
	padding: 0;

	${props => {
		if (['gridLeft', 'gridRight'].indexOf(props.layout) > -1) {
			return css`
				max-width: 891px;
			`;
		}
		if (props.layout === 'gridCentre') {
			return css`
				max-width: 584px;
			`;
		}
		return null;
	}};

	${media.desktop`
		margin: ${WIDGET_SPACING_LARGE}px auto;
	`};

	${props => {
		if (['gridLeft', 'gridRight'].indexOf(props.layout) > -1) {
			return css`
				& + & {
					margin-top: ${WIDGET_SPACING_MEDIUM * -1 + 10}px;

					${media.tablet`
						margin-top: ${WIDGET_SPACING_MEDIUM * -1 + 20}px;
					`};
					${media.desktop`
						margin-top: ${WIDGET_SPACING_LARGE * -1 + 30}px;
					`};
				}
			`;
		}
		return null;
	}};

	.recipe-highlights-header {
		margin-top: 0;
		text-align: center;
	}

	.recipe-highlights-text {
		margin: 0 0 20px;
	}

	.recipe-highlight-link {
		margin-top: 30px;
	}

	${({ theme }) => theme.styledVariations.recipeHighlight}
`;

const WidgetHeader = styled(MediumHeader)`
	max-width: 720px;
	color: ${props => props.color};
`;

const recipeCardGridDefault = css`
	margin: 0;
	flex: none;
	width: 100%;

	/* IE 11 Hack */
	*::-ms-backdrop,
	& {
		float: left;
		margin: 15px;
		width: calc(100% / 3 - 30px);
	}
`;

const RecipeHighlightGrid = styled.div<LayoutProps>`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: -5px;
	width: 100%;

	${media.tablet<LayoutProps>`
		${props => {
			if (['gridLeft', 'gridRight'].indexOf(props.layout) > -1) {
				return css`
					margin: 0;
					display: grid;
					grid-gap: 20px;
					grid-template-columns: repeat(3, 1fr);

					/* IE 11 Hack */
					*::-ms-backdrop,
					& {
						display: block;
						overflow: hidden;
						margin: -15px;
					}
				`;
			}
			return null;
		}};
	`};

	${media.desktop<LayoutProps>`
		${props => {
			if (['gridLeft', 'gridRight'].indexOf(props.layout) > -1) {
				return css`
					grid-gap: 30px;
				`;
			}
			return null;
		}};
	`};

	.recipe-card {
		margin: 5px;
		flex: 0 1 calc(50% - 10px);
		width: calc(50% - 10px);

		${media.tablet<LayoutProps>`
			${props => {
				if (['gridLeft', 'gridRight'].indexOf(props.layout) > -1) {
					return recipeCardGridDefault;
				}
				return null;
			}};
		`};

		&:first-child {
			max-width: none;
			flex: 1 1 100%;
			width: 100%;

			${media.tablet<LayoutProps>`
				${props => {
					if (['gridLeft', 'gridRight'].indexOf(props.layout) > -1) {
						return css`
							flex: none;
							grid-row: 1 / span 2;
							grid-column: ${props.layout === 'gridLeft' ? 1 : 2} / span 2;
							max-width: none;

							/* IE 11 Hack */
							*::-ms-backdrop,
							& {
								width: calc(100% / 3 * 2 - 30px);
								float: ${props.layout === 'gridRight' ? 'right' : 'left'};
							}
						`;
					}
					return null;
				}};

				.recipe-card-image-wrapper {
					flex: 1 0 auto;
				}

				.recipe-card-title {
					flex: 0 0 auto;
				}

			`};
		}

		&:nth-child(even):last-child {
			max-width: none;
			flex: 1 1 100%;
			width: 100%;

			${media.tablet<LayoutProps>`
				${props => {
					if (['gridLeft', 'gridRight'].indexOf(props.layout) > -1) {
						return recipeCardGridDefault;
					}
					return null;
				}};
			`};
		}

		${props => {
			if (['gridLeft', 'gridRight'].indexOf(props.layout) > -1) {
				return css`
					&:nth-child(4) {
						/* IE 11 Hack */
						*::-ms-backdrop,
						& {
							clear: both;
						}
					}
				`;
			}
			return null;
		}};
	}
`;

const RecipeHighlight: React.FC<WidgetCommonProps & RecipeHighlightConfiguration & InjectedRoutableProps> = ({
	widgetId,
	title,
	text,
	recipes,
	additionalLink,
	additionalLinkText,
	layoutName,
	onLinkClick,
}) => {
	if (recipes.length < 1) {
		return null;
	}

	const renderRecipeCard = ({ imageUrl, name, url, type, totalTime, rating }: RecipeHighlightRecipe) => {
		return (
			<RecipeCard
				key={generate()}
				imageUrl={`${imageUrl}/716x462-recipe-highlight`}
				recipeName={name}
				url={url}
				type={type}
				totalTime={totalTime}
				rating={rating}
				onClick={onLinkClick}
			/>
		);
	};

	return (
		<ContentThemeContext.Consumer>
			{contentTheme => (
				<RecipeHighlightContentWrapper id={widgetId} layout={layoutName}>
					{title && (
						<WidgetHeader className="recipe-highlights-header" color={contentTheme.color}>
							{title}
						</WidgetHeader>
					)}

					{text && (
						<WidgetText
							className="recipe-highlights-text"
							dangerouslySetInnerHTML={{ __html: convertLineBreaks(text) }}
						/>
					)}

					{(!layoutName ||
						(layoutName !== 'gridLeft' && layoutName !== 'gridRight' && layoutName !== 'gridCentre')) && (
						<RecipeCardGrid layout={layoutName}>{recipes.map(recipe => renderRecipeCard(recipe))}</RecipeCardGrid>
					)}

					{['gridLeft', 'gridRight', 'gridCentre'].indexOf(layoutName) > -1 && (
						<RecipeHighlightGrid layout={layoutName}>
							{recipes.map(recipe => renderRecipeCard(recipe))}
						</RecipeHighlightGrid>
					)}

					{additionalLink && additionalLinkText && (
						<OutlinedButtonLink href={additionalLink} className="recipe-highlight-link" color={contentTheme.color}>
							{additionalLinkText}
						</OutlinedButtonLink>
					)}
				</RecipeHighlightContentWrapper>
			)}
		</ContentThemeContext.Consumer>
	);
};

RecipeHighlight.defaultProps = {
	layoutName: 'list',
};

export default injectRoutable(RecipeHighlight);
