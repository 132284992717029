import * as React from 'react';
import { LinkGroupConfiguration } from './interfaces/LinkGroup';
import styled, { css } from 'styled-components';
import { media } from 'styleguide/helpers/media';
import { WIDGET_SPACING_MEDIUM, WIDGET_SPACING_LARGE } from './constants';
import { ContentThemeContext, ContentTheme } from '../../contexts/ContentThemeContext';
import { WidgetCommonProps } from './interfaces/interfaces';
import MediumHeader from 'styleguide/components/Typography/MediumHeader/MediumHeader';

const LinkGroupList = styled.article`
	margin: ${WIDGET_SPACING_MEDIUM}px auto;

	${media.desktop`
    margin: ${WIDGET_SPACING_LARGE}px auto;
  `};
`;

const LinkGroupContainer = styled.div`
	${media.phone560`
  	margin: -10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  `};

	${media.tablet`
    margin: -20px;
  `};
`;

const Link = styled.a<ContentTheme>`
	${({ color, theme }) => css`
		color: ${color ? color : theme.colors.linkText};
	`};

	font-weight: 600;
	font-size: 15px;
	text-decoration: none;
	margin: 20px 0;
	width: 100%;
	display: block;

	${media.phone560`
  	margin: 10px;
    flex: 0 1 calc(50% - 40px);
  `};

	${media.tablet`
    margin: 20px;
  `};

	${media.desktop`
    flex: 0 1 auto;
    flex: 0 1 calc(33% - 40px);
		width: calc(33% - 40px);
  `};
`;

const Image = styled.img`
	width: 100%;
	border-radius: 2px;
`;

const LinkText = styled.span`
	display: flex;
	align-items: center;
	padding: 8px 0px;
`;

const WidgetHeader = styled(MediumHeader)`
	margin-top: 0;
	text-align: center;
	color: ${props => props.color};
`;

const LinkGroup: React.FC<WidgetCommonProps & LinkGroupConfiguration> = ({ widgetId, title, items }) => {
	if (items.length < 1) {
		return null;
	}
	return (
		<ContentThemeContext.Consumer>
			{contentTheme => (
				<LinkGroupList id={widgetId}>
					{title && <WidgetHeader {...contentTheme}>{title}</WidgetHeader>}
					<LinkGroupContainer>
						{items.map(({ linkText, linkUrl, imageUrl }, index) => (
							<Link key={`${linkUrl}-${index}`} href={linkUrl} title={linkText} color={contentTheme.color}>
								<Image src={`${imageUrl}/570x380-link-group`} alt={linkText} />
								<LinkText>{linkText}</LinkText>
							</Link>
						))}
					</LinkGroupContainer>
				</LinkGroupList>
			)}
		</ContentThemeContext.Consumer>
	);
};

export default LinkGroup;
