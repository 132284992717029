import { EventTime } from './interfaces';

const timeFormatOptions = { hour: '2-digit', minute: '2-digit' };
const longDateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

export function formatEventTimeWithTimeZone(eventTIme: EventTime, locales?: string | string[]) {
	const startDate = new Date(eventTIme.startTime);
	const endDate = new Date(eventTIme.endTime);
	return `${startDate.toLocaleTimeString(locales, timeFormatOptions)} - ${endDate.toLocaleTimeString(
		locales,
		timeFormatOptions
	)} (${eventTIme.timeZone.abbreviation})`;
}

export function formatEventDate(eventTIme: EventTime, locales?: string | string[]) {
	const date = new Date(eventTIme.startTime);
	return date.toLocaleDateString(locales);
}

export function formatEventDateLong(eventTIme: EventTime, locales?: string | string[]) {
	const date = new Date(eventTIme.startTime);
	return date.toLocaleDateString(locales, longDateOptions);
}

export function formatEventDateWithTimeAndTimeZone(eventTIme: EventTime, locales?: string | string[]) {
	return `${formatEventDateLong(eventTIme, locales)}, ${formatEventTimeWithTimeZone(eventTIme, locales)}`;
}
